<template>
  <f7-list-input
    type="texteditor"
    :placeholder="autoSave ? $t('placeholder-autosave') : $t('placeholder')"
    :value="object ? object.notesHtml : ''"
    :text-editor-params="{
      mode: 'keyboard-toolbar',
      buttons: ['bold', 'italic', 'underline', 'strikeThrough'],
      clearFormattingOnPaste: false
    }"
    resizable
    v-bind="$attrs"
    class="notes"
    v-on="$listeners"
    @change="change"
    @blur="blur"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import Tribute from 'tributejs';
import DBObject from '../class/dbOject.class';
import Contact from '../class/contact.class';

export default {
  props: {
    autoSave: {
      type: Boolean,
      default: false,
    },
    object: {
      type: DBObject,
      default: null,
    },
    mentionsPriority: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      formNotes: null,
      tribute: null,
    };
  },
  computed: {
    ...mapGetters(['contacts', 'hashtagsStats']),
  },
  mounted() {
    const textEditorEl = this.$el.querySelector('.text-editor-content');
    this.formNotes = this.object ? this.object.notesHtml : ''; // Initialize the notes
    const _self = this;
    this.tribute = new Tribute({
      noMatchTemplate() {
        if (this.current.collection.trigger === '#') return null;
        if (this.current.collection.trigger === '@') return `<li>${_self.$t('mention-noMatchFound')}</li>`;
        return null;
      },
      allowSpaces: false,
      collection: [
        {
          trigger: '@',
          requireLeadingSpace: true,
          values: this.mentionsPriority ? [...new Set([...this.mentionsPriority, ...this.contacts])] : this.contacts, // We add the priority mentions at the top of the list
          lookup: 'name',
          fillAttr: 'id',
          selectTemplate(item) {
            return `<span class="delete"><span contenteditable="false"><a href="/contact/${item.original.id}/" data-mention="${item.original.id}" class="mention link">@${item.original.name}</a></span></span>`;
          },
          noMatchTemplate: `<li><span>${this.$t('mention-noMatchFound')}</span></li>`,
        },
        {
          trigger: '#',
          requireLeadingSpace: true,
          values: (text, cb) => {
            if (text) {
              const sanitizedText = text
                .normalize('NFD') // Separate accent character from the letter : È -> E + `
                .replace(/[\u0300-\u036f]/g, '') // Remove the accents characters
                .replace(/ /g, '-') // Replace the spaces by dashes
                .replace(/[^a-zA-Z0-9-_]/g, ''); // Remove everything that is not letter, number, dash or underscore
              cb([...this.hashtagsStats, { hits: 0, name: sanitizedText }]);
            } else cb(this.hashtagsStats);
          },
          lookup: 'name',
          menuItemTemplate(item) {
            if (item.original.hits) return `${item.string} <small>(x${item.original.hits})</small>`;
            return `${item.string} <small>(new)</small>`;
          },
          spaceSelectsMatch: true,
          selectTemplate(item) {
            return `<span class="delete"><span contenteditable="false"><a href="/p/hashtag-search/${item.original.name}/" class="hashtag link">#${item.original.name}</a></span></span>`;
          },
        },
      ],
    });
    this.tribute.attach(textEditorEl);
    // Emit an event if a contact has been mentionned
    textEditorEl.addEventListener('tribute-replaced', (e) => {
      if (e.detail.item.original instanceof Contact) {
        this.$emit('contact-mentioned', e.detail.item.original);
      }
    });
    // Prevent keyboard to open on phones when clicking link inside
    textEditorEl.addEventListener('click', (event) => {
      if (event.target.tagName.toLowerCase() === 'a') {
        textEditorEl.blur();
      }
    });
    // Sanitize paste (extremely hard to sanitize word html, so we let the brwoser take care of it THEN we sanitize)
    textEditorEl.addEventListener('paste', () => {
      setTimeout(() => { textEditorEl.innerHTML = DBObject.sanitizeHtml(textEditorEl.innerHTML); }, 10);
    });
    // Sanitize drop (not perfect... But do the trick approximately...)
    textEditorEl.addEventListener('drop', () => {
      setTimeout(() => { textEditorEl.innerHTML = DBObject.sanitizeHtml(textEditorEl.innerHTML); }, 10);
    });
    // Uniformize browser behaviors
    document.execCommand('defaultParagraphSeparator', false, 'div');
    document.execCommand('styleWithCSS', false, false);
    // Intercept [Enter] keydown to insert <br> instead of new div
    textEditorEl.addEventListener('keydown', (e) => {
      // trap the return key being pressed (when tribute dropdown is not open, to not interfere with item selection)
      if (e.keyCode === 13 && !this.tribute.isActive) {
        // insert a simple line break
        window.document.execCommand('insertLineBreak', false, null);
        // and prevent the default behavior of the browser
        e.preventDefault();
      }
      // If backspace is pressed, we help the browser to correctly delete the hashtags and mentions
      // It's a completely custom code, made with trial and error... It's not guaranteed viable for the long run!
      if (e.keyCode === 8) {
        const selObj = window.getSelection();
        const range = selObj.getRangeAt(0);
        if (selObj.anchorNode.nodeType !== Node.TEXT_NODE) {
          const anchor = selObj.anchorNode;
          if (anchor.classList && anchor.classList.contains('delete')) {
            range.selectNode(anchor);
            anchor.remove();
            e.preventDefault();
          }
          const selectedChild = selObj.anchorNode.childNodes[selObj.anchorOffset - 1];
          if (selectedChild && selectedChild.classList && selectedChild.classList.contains('delete')) {
            range.selectNode(selectedChild);
            selectedChild.remove();
            e.preventDefault();
          }
        }
      }
    });
    /*
    // Helper for deleting mentions and hashtags
    // http://jsfiddle.net/Sviatoslav/4d23y74j/
    textEditorEl.addEventListener('keydown', function (event) {
      if (window.getSelection && event.which === 8) { // backspace
        // fix backspace bug in FF
        // https://bugzilla.mozilla.org/show_bug.cgi?id=685445
        const selection = window.getSelection();
        if (!selection.isCollapsed || !selection.rangeCount) {
          return;
        }

        const curRange = selection.getRangeAt(selection.rangeCount - 1);
        if (curRange.commonAncestorContainer.nodeType === 3 && curRange.startOffset > 0) {
          // we are in child selection. The characters of the text node is being deleted
          return;
        }

        const range = document.createRange();
        if (selection.anchorNode !== this) {
          // selection is in character mode. expand it to the whole editable field
          range.selectNodeContents(this);
          range.setEndBefore(selection.anchorNode);
        } else if (selection.anchorOffset > 0) {
          range.setEnd(this, selection.anchorOffset);
        } else {
          // reached the beginning of editable field
          return;
        }
        range.setStart(this, range.endOffset - 1);

        const previousNode = range.cloneContents().lastChild;
        if (previousNode && previousNode.contentEditable === 'false') {
          // this is some rich content, e.g. smile. We should help the user to delete it
          range.deleteContents();
          event.preventDefault();
        }
      }
    }); */
  },
  beforeDestroy() {
    if (this.tribute) {
      this.tribute.detach(this.$el.querySelector('.text-editor-content'));
      this.tribute = null;
    }
  },
  methods: {
    change(value) {
      this.formNotes = value;
    },
    blur() {
      this.$emit('edition-completed', this.formNotes);
      if (this.object && this.autoSave) {
        const oldNotes = this.object.notes;
        this.object.notes = this.formNotes;
        // If the notes have changed we update them on the server
        if (oldNotes !== this.object.notes) {
          this.$store.dispatch('updateNotes', this.object);
        }
      }
    },
  },
};
</script>

<style lang="less">
.notes .text-editor-content {
  min-height: 100px;
  overflow-x: hidden;
}
.text-editor-content a {
  pointer-events: auto;
}
.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  background: var(--f7-autocomplete-dropdown-bg-color);
  box-shadow: var(--f7-autocomplete-dropdown-box-shadow);
  box-sizing: border-box;
  z-index: 999999;
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
}
.tribute-container li {
  padding: 5px 5px;
  cursor: pointer;
}
.tribute-container li.highlight {
  background: var(--f7-autocomplete-dropdown-selected-bg-color,rgba(var(--f7-theme-color-rgb),.2));
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}
.tribute-container small {
  color: gray;
  font-variant: small-caps;
}
</style>

<i18n>
{
  "en": {
    "placeholder": "Write anything.",
    "placeholder-autosave": "Write anything.<br />Auto-saved.",
    "mention-noMatchFound": "No match found",
    "hashtag-noMatchFound": "Create your 1st hashtag!"
  },
  "fr": {
    "placeholder": "Écrivez ce que vous voulez.",
    "placeholder-autosave": "Écrivez ce que vous voulez.<br />Sauvegarde automatique.",
    "mention-noMatchFound": "Pas de contact",
    "hashtag-noMatchFound": "Créez votre 1er hashtag!"
  }
}
</i18n>
