<template>
  <f7-page name="calendar">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block>
      <h3>Pour ne louper aucun rendez-vous, ajoutez les rappels et les interactions à venir que vous avez enregistrées dans Kipinto directement dans votre calendrier</h3>
    </f7-block>
    <f7-block v-if="!(userDoc && userDoc.ical && userDoc.ical.token)" strong>
      <p>Pour ce faire, nous allons vous créer un lien unique pour accéder à votre calendrier.</p>
      <p>
        <f7-button v-if="newTokenLoading" fill large round disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button v-else fill large round @click="generateToken">
          {{ $t('calendar-getToken') }}
        </f7-button>
      </p>
      <p class="text-align-center">
        <em>Nécessite d'être connecté à internet.</em>
      </p>
    </f7-block>
    <f7-block v-else strong>
      <p>Cliquer ce bouton ouvrira votre application de calendrier par défaut si elle est compatible.</p>
      <p>
        <f7-button v-if="newTokenLoading" fill large round disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button
          v-else
          fill
          large
          round
          :href="calendarLink"
          external
          :target="$f7.device.cordova ? '_system' : '_self'"
        >
          {{ $t('calendar-getLink') }}
        </f7-button>
      </p>
      <p>
        Si votre application n'est pas compatible avec l'ajout automatique, copiez le lien ci-dessous, et ajoutez le manuellement à votre calendrier (nécessite qu'il soit compatible avec les calendriers iCal ou ICS).
      </p>
      <f7-list no-hairlines class="no-margin">
        <f7-list-input
          ref="calendarLink"
          class="calendar-link"
          outline
          label="Calendrier ICS / iCAL"
          floating-label
          type="text"
          :value="calendarLink"
        >
          <f7-button slot="root-end" @click="copy">
            Copier
          </f7-button>
        </f7-list-input>
      </f7-list>
      <p>Si besoin, vous pouvez regénérer un nouveau lien sécurisé qui invalidera tous les liens existants.</p>
      <p>
        <f7-button v-if="newTokenLoading" small disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button v-else small @click="generateToken">
          {{ $t('calendar-getToken') }}
        </f7-button>
      </p>
    </f7-block>
    <f7-block>
      <p>Note : selon votre outil de calendrier, les mises à jours du calendrier peuvent avoir lieu entre 1 fois par heure et 1 fois par jour.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      newTokenLoading: false,
      generatedToken: null,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['user', 'userDoc']),
    calendarLink() {
      return `webcal://kipinto-dev-f7v4.web.app/kipinto.ics?user=${this.user.uid}&token=${this.generatedToken || this.userDoc.ical.token}`;
    },
  },
  mounted() {},
  methods: {
    async generateToken() {
      this.newTokenLoading = true;
      this.generatedToken = await this.$store.dispatch('getICalToken');
      this.newTokenLoading = false;
    },
    copy(e) {
      const input = this.$refs.calendarLink.$el.querySelector('input');
      console.log('calendar link', this.$refs.calendarLink.$el, input);
      input.select();
      document.execCommand('copy');
      this.$f7.toast.show({ text: 'Lien copié !' });
      e.preventDefault();
    },
  },
};
</script>

<style lang="less" scoped>
.calendar-link {
  display: flex;
  justify-content: center;
  align-items: center;

  /deep/ .item-content {
    flex-grow: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Add to Calendar",
    "calendar-getToken": "Create my secured link",
    "calendar-getLink": "Add Kipinto to my calendar"
  },
  "fr": {
    "title": "Ajouter au Calendrier",
    "calendar-getToken": "Créer mon lien sécurisé",
    "calendar-getLink": "Ajouter Kipinto à mon calendrier"
  }
}
</i18n>
