<template>
  <f7-app :params="f7params">
    <!-- Left panel with cover effect-->
    <f7-panel left reveal theme-dark>
      <f7-view url="/parameters/" name="parameters" />
    </f7-panel>

    <!-- Views/Tabs container -->
    <f7-views tabs class="safe-areas">
      <!-- Tabbar for switching views-tabs -->
      <f7-toolbar tabbar bottom>
        <f7-link
          tab-link="#view-events"
          icon-ios="f7:calendar_today"
          icon-aurora="f7:calendar_today"
          icon-md="material:today"
        />
        <f7-link
          tab-link="#view-home"
          tab-link-active
          icon-ios="f7:house"
          icon-aurora="f7:house"
          icon-md="material:home"
        />
        <f7-link
          tab-link="#view-contacts"
          icon-ios="f7:person_2"
          icon-aurora="f7:person_2"
          icon-md="material:group"
        />
      </f7-toolbar>

      <!-- Events View -->
      <f7-view id="view-events" name="events" tab url="/events/" />

      <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
      <f7-view
        id="view-home"
        name="home"
        main
        tab
        tab-active
        url="/home/"
      />

      <!-- Contacts View -->
      <f7-view id="view-contacts" name="contacts" tab url="/contacts/" />
    </f7-views>

    <!-- Popup -->
    <f7-popup tablet-fullscreen :opened="showIntro">
      <f7-view name="intro" url="/intro/" />
    </f7-popup>

    <f7-popup tablet-fullscreen :opened="userMustVerifyEmail">
      <f7-view name="verify-email" url="/verify-email/" />
    </f7-popup>

    <f7-popup tablet-fullscreen :opened="dbLoaded && mustUpdateDb">
      <f7-view name="update-db" url="/update-db/" />
    </f7-popup>

    <f7-login-screen :opened="showLogin">
      <f7-view name="login" url="/login-signup/" />
    </f7-login-screen>
  </f7-app>
</template>

<script>
import { mapGetters } from 'vuex';

import firebase from '../js/firebase-config'; // REDUCE SIZE
import cordovaApp from '../js/cordova-app';
import routes from '../js/routes';


export default {
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        id: 'com.kipinto.app', // App bundle ID
        name: 'Kipinto', // App name
        theme: 'auto', // Automatic theme detection
        // App root data
        data() {
          return { };
        },

        // App routes
        routes,

        smartSelect: {
          pageBackLinkText: this.$t('common.back'),
        },

        toast: {
          closeTimeout: 5000,
          destroyOnClose: true,
          closeButton: true,
        },

        dialog: {
          buttonOk: this.$t('common.ok'),
          buttonCancel: this.$t('common.cancel'),
        },

        // Register service worker
        serviceWorker: this.$device.cordova
          ? {}
          : {
            path: '/service-worker.js',
          },
        // Input settings
        input: {
          scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
          scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      },
      // LoginPage
      showLogin: false,
      showIntro: false,
    };
  },
  computed: {
    ...mapGetters([
      'userMustVerifyEmail', 'mustUpdateDb', 'dbLoaded',
    ]),
  },
  mounted() {
    this.$f7ready((f7) => {
      // Remove loading class on html
      document.documentElement.classList.remove('loading');

      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);
      }
      // Call F7 APIs here

      // Handle the case of signin / signup / linkin where the app activity is destroyed:
      firebase.auth().getRedirectResult().then((result) => {
        if (result.credential) {
          console.log('getRedirectResult', result);
          this.$store.commit('setUser', result.user);
          // this.loginSuccessful('google', result);
        }
      }).catch((error) => {
        this.$f7.dialog.alert(`Oopsie in getRdirectResult. ${error.message}`);
      });

      // We enable analytics
      this.$store.dispatch('enableAnalytics', true);

      // We follow the current displayed path
      let currentlyDisplayedPath = null;
      this.$f7.on('tabShow panelOpen panelClose pageAfterIn popupOpened popupClosed loginScreenOpened loginScreenClosed', (page) => {
        let path = null;
        if (page.type === 'popup' && page.opened && page.route) path = page.route.path.slice(0, 3) === '/p/' ? page.route.path.slice(2) : page.route.path;
        else path = this.$f7.views.current.history[this.$f7.views.current.history.length - 1];
        if (path && currentlyDisplayedPath !== path) {
          currentlyDisplayedPath = path;
          this.$store.dispatch('trackPage', path);
        }
      });

      // We hide/show login form based on user authentication and if he has read the intro or not
      let appLoaded = false; // See below for explanation
      firebase.auth().onAuthStateChanged(() => {
        const user = firebase.auth().currentUser;
        console.log('onAuthStateChanged app.vue');
        // We hide / show the intro and the login page
        let introRead = false;
        try {
          introRead = JSON.parse(localStorage.getItem('introRead'));
        } catch (error) { console.error(error); }
        console.log('authentication change detected');
        this.showLogin = !user && introRead;
        this.showIntro = !user && !introRead;
        // If the user hasn't verified its email, we remind him to do so
        if (user && !user.emailVerified && !user.isAnonymous && !this.userMustVerifyEmail) {
          this.$f7.dialog.create({
            title: this.$t('verifyEmail-title'),
            text: this.$t('verifyEmail-text'),
            buttons: [
              {
                text: this.$t('verifyEmail-resend'),
                onClick: () => {
                  this.$store.dispatch('sendEmailVerification')
                    .then(() => this.$f7.toast.show({ text: this.$t('verifyEmail-sent') }));
                },
              },
              { text: this.$t('common.ok'), bold: true },
            ],
            verticalButtons: true,
          }).open();
        }
        // We reload all the views if the user has changed
        // When the app load the first time, the routes are already correct, so a reload is not necessary
        // TODO: clearPreviousHistory does not work
        if (appLoaded) {
          const settingsReload = { reloadAll: true };
          this.$f7.panel.close('left', false);
          this.$f7.views.parameters.router.navigate('/parameters/', settingsReload);
          this.$f7.views.home.router.navigate('/home/', settingsReload);
          this.$f7.views.events.router.navigate('/events/', settingsReload);
          this.$f7.views.contacts.router.navigate('/contacts/', settingsReload);
        }
        appLoaded = true;
      });

      // Clicking again a tab already selected move it back to first page
      const $ = this.$$;
      const app = this.$f7;
      let lastTab = $('#framework7-root > .views > .toolbar .tab-link.tab-link-active').attr('data-tab');
      $(document).on('click', '#framework7-root > .views > .toolbar .tab-link.tab-link-active', function () {
        const currentTab = $(this).attr('data-tab');
        if (currentTab === lastTab) {
          const $viewEl = $(currentTab);
          const view = app.views.get($viewEl);
          console.log('view history before', view.history);
          if (view.history.length > 1) {
            view.router.back(view.history[0], {
              force: true,
            });
            console.log('view history after', view.history);
          }
        }
        lastTab = currentTab;
      });

      // If on Cordova, we check if there is an update from the App Center
      if (window.codePush) {
        window.codePush.sync((status) => {
          if (status === 1) { // SyncStatus: UPDATE INSTALLED
            this.notifyUserOfUpdate(() => {
              window.codePush.restartApplication();
            });
          }
        });
      }

      // When a new service worker is installed, we notify the user so he can restart the app
      f7.on('serviceWorkerRegisterSuccess', (registration) => {
        console.log('serviceWorkerRegisterSuccess');
        const reg = registration;
        reg.onupdatefound = () => {
          console.log('serviceWorker onupdatefound');
          const installingWorker = reg.installing;
          installingWorker.onstatechange = () => {
            console.log('serviceWorker installingWorker onstatechange');
            if (installingWorker.state === 'installed') {
              console.log('serviceWorker installed');
              if (navigator.serviceWorker.controller) {
                console.log('serviceWorker navigator.serviceWorker.controller');
                // We inform the user a new update is available
                this.notifyUserOfUpdate(() => {
                  window.location.reload();
                });
              }
            }
          };
        };
      });
    });
  },
  methods: {
    notifyUserOfUpdate(restart) {
      this.$f7.notification.create({
        icon: '<img src="static/icons/favicon.png">',
        title: 'Kipinto',
        subtitle: this.$t('newUpdate-title'),
        text: this.$t('newUpdate-text'),
        closeButton: true,
        on: {
          click() {
            restart();
          },
        },
      }).open();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "newUpdate-title": "New update available!",
    "newUpdate-text": "It will be installed the next time the app is restarted, or you can click on this notification to reload the app and apply the update immediately.",
    "verifyEmail-title": "Confirm Email",
    "verifyEmail-text": "You received an email to verify your email address. Remember to click on the link it contains!",
    "verifyEmail-resend": "Resend the email",
    "verifyEmail-sent": "Verification email resent"
  },
  "fr": {
    "newUpdate-title": "Mise-à-jour disponible !",
    "newUpdate-text": "Elle sera installée lors du prochain redémarrage de l'application, ou vous pouvez cliquer sur cette notification pour redémarrer l'application et installer la mise-à-jour immédiatement.",
    "verifyEmail-title": "Email à confirmer",
    "verifyEmail-text": "Vous avez reçu un email pour vérifier votre adresse email. Pensez à cliquer sur le lien qu'il contient !",
    "verifyEmail-resend": "Renvoyer l'email",
    "verifyEmail-sent": "Email de vérification renvoyé"
  }
}
</i18n>
