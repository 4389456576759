<template>
  <f7-page name="intro" class="page-intro">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon-ios="f7:globe" icon-aurora="f7:globe" icon-md="material:translate" popover-open=".popover-language" />
      </f7-nav-left>
      <f7-nav-title>{{ $t('welcome') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link v-show="!reachEnd" @click="skip">
          {{ $t('skip') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-swiper ref="intro" pagination navigation :params="{speed:600, parallax: true, observer: true, observeParents: true, mousewheel: true}">
      <div
        slot="before-wrapper"
        class="parallax-bg"
        data-swiper-parallax="-60%"
      />
      <f7-swiper-slide v-for="slide in [1,2,3,4]" :key="slide">
        <div class="slide-bg">
          <img :src="'static/images/intro/step-'+slide+'.svg'">
        </div>
        <div class="overlay">
          <div class="overlay-container">
            <div class="title" data-swiper-parallax="-300">
              {{ $t('slide-'+slide+'-title') }}
            </div>
            <div class="subtitle" data-swiper-parallax="-200">
              {{ $t('slide-'+slide+'-subtitle') }}
            </div>
            <div class="text" data-swiper-parallax="-100">
              {{ $t('slide-'+slide+'-text') }}
            </div>
          </div>
        </div>
      </f7-swiper-slide>
      <f7-swiper-slide>
        <div class="slide-bg">
          <img src="static/images/intro/step-5.svg">
        </div>
        <div class="overlay">
          <div class="overlay-container">
            <div class="title" data-swiper-parallax="-300">
              {{ $t('lets-go') }}
            </div>
            <p data-swiper-parallax="-200">
              <f7-button fill large round href="/login-signup/?showNavbar=true&mode=signup">
                {{ $t('login-signup') }}
              </f7-button>
            </p>
            <p data-swiper-parallax="-100">
              <f7-button large @click="anonymousLogin">
                {{ $t('test-kipinto') }}
              </f7-button>
            </p>
          </div>
        </div>
      </f7-swiper-slide>
    </f7-swiper>
    <f7-popover class="popover-language">
      <f7-list>
        <f7-list-item popover-close title="English" @click="changeLanguage('en')" />
        <f7-list-item popover-close title="Français" @click="changeLanguage('fr')" />
      </f7-list>
    </f7-popover>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      reachEnd: false,
    };
  },
  mounted() {
    const _self = this;
    this.$refs.intro.swiper.on('reachEnd', () => {
      _self.reachEnd = true;
      console.log('reachEnd', _self.reachEnd);
      // We try to save that the user has read the intro
      try {
        localStorage.setItem('introRead', true);
      } catch (error) { console.error(error); }
    });
  },
  methods: {
    skip() {
      this.$refs.intro.swiper.slideTo(4);
    },
    changeLanguage(locale) {
      this.$root.$i18n.locale = locale;
      try {
        localStorage.setItem('locale', locale);
      } catch (error) { console.error(error); }
      this.$f7.popover.close('.popover-language');
    },
    anonymousLogin() {
      this.$f7.dialog.confirm(
        this.$t('testAccount-explanation'),
        this.$t('testAccount-create'),
        () => {
          this.$store.dispatch('signUserAnonymously');
        },
      );
    },
  },
};
</script>


<style lang="less" scoped>
.page-intro {
  --f7-page-bg-color: #FFF;

  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 40px);
    width: 300%;
    background-image: url(../static/images/intro/background.svg);
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center bottom;
  }
  .swiper-slide {
    position: relative;
    font-size: 18px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: calc(100% - 40px);

    .slide-bg {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      text-align: center;

      img {
        height: 100%;
        margin: 0 -100%;
      }
    }
    .overlay {
      // background: rgba(255,255,255,0.8);
      backdrop-filter: blur(10px) saturate(150%);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50%;
      overflow: hidden;
    }
    .overlay-container {
      padding: 0 45px;
    }
    .title {
      font-size: 41px;
      font-weight: 300;
      margin-bottom: 0.5em;
    }
    .subtitle {
      font-size: 18px;
      margin-bottom: 0.5em;
    }
    .text {
      font-size: 14px;
      max-width: 400px;
      margin: auto;
      line-height: 1.3;
    }
    .button {
      margin: auto;
      width: fit-content;
      padding: 0 1em;
    }
    :last-child {
      margin-bottom: 0;
    }
  }

  /deep/ .swiper-button-prev, /deep/ .swiper-button-next {
    top: calc(50% - 20px);

    @media screen and (max-width: 640px) {
        display: none;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "skip": "Skip",
    "welcome": "Welcome",
    "slide-1-title": "Kipinto",
    "slide-1-subtitle": "Welcome to Kipinto, the application that will help you grow and develop your network",
    "slide-1-text": " ",
    "slide-2-title": "Contacts",
    "slide-2-subtitle": "Build your list of relevant contacts",
    "slide-2-text": "No rush to the number, only the quality, which must be cultivated",
    "slide-3-title": "Interactions",
    "slide-3-subtitle": "Complete your thread of interactions, past and future",
    "slide-3-text": "Our reminders put regularity at the centre of your network's development",
    "slide-4-title": "Notes",
    "slide-4-subtitle": "Take notes to enrich your interactions and contacts.",
    "slide-4-text": "Differentiating and private information, because it is the result of a true relationship",
    "lets-go": "Let's go!",
    "login-signup": "Signup / Login",
    "test-kipinto": "Test Kipinto without account",
    "testAccount-create": "Create test account",
    "testAccount-explanation": "<p><strong>We cannot guarante the durability of test account data.</strong></p><p>To retrieve your data from any device, you can convert your test account into a permanent account at any time.</p><p style='font-size: 0.8em'>By clicking ok, you confirm agreeing the <a href='/p/legals/?tab=tos'>TOS</a> and having read the <a href='/p/legals/?tab=privacy'>Privacy Policy</a>.</p>"
  },
  "fr": {
    "skip": "Passer",
    "welcome": "Bienvenue",
    "slide-1-title": "Kipinto",
    "slide-1-subtitle": "Bienvenue sur Kipinto, l'application qui va vous permettre de cultiver et de faire fructifier votre réseau",
    "slide-1-text": " ",
    "slide-2-title": "Contacts",
    "slide-2-subtitle": "Construisez votre répertoire de contacts pertinents",
    "slide-2-text": "Pas de course au nombre, seulement de la qualité, qu'il faudra cultiver",
    "slide-3-title": "Interactions",
    "slide-3-subtitle": "Complétez votre fil d'interactions, passées et à venir",
    "slide-3-text": "Nos rappels mettent la régularité au centre du développement de votre réseau",
    "slide-4-title": "Notes",
    "slide-4-subtitle": "Prenez des notes pour enrichir vos interactions et contacts",
    "slide-4-text": "De l'information différentiante et privée, parce que fruit d'une véritable relation",
    "lets-go": "C'est parti !",
    "login-signup": "S'inscrire / Se connecter",
    "test-kipinto": "Tester Kipinto sans compte",
    "testAccount-create": "Créer un compte de test",
    "testAccount-explanation": "<p><strong>Nous ne pouvons garantir la pérennité des données des comptes de test.</strong></p><p>Pour retrouver vos données depuis n'importe quel appareil, vous pourrez à tout moment convertir votre compte de test en compte permanent.</p><p style='font-size: 0.8em'>En cliquant sur OK, vous confirmez accepter les <a href='/p/legals/?tab=tos'>CGUs</a> et avoir lu la <a href='/p/legals/?tab=privacy'>Politique de Confidentialité</a>.</p>"
  }
}
</i18n>
