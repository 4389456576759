<template>
  <f7-page name="stats">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block strong class="userStats">
      <f7-row>
        <f7-col>
          <h1>{{ stats.contact }}</h1>
          <p>{{ $t('contacts') }}</p>
        </f7-col>
        <f7-col>
          <h1>{{ stats.interaction }}</h1>
          <p>{{ $t('interactions') }}</p>
        </f7-col>
        <f7-col>
          <h1>{{ stats.memo }}</h1>
          <p>{{ $t('memos') }}</p>
        </f7-col>
      </f7-row>
    </f7-block>
    <f7-block-title>{{ $t('month-evolution') }}</f7-block-title>
    <f7-block strong>
      <area-chart :data="statsMonth" :dataset="{ pointRadius: 0 }" :colors="['#222222', '#4c889d', '#efd06a']" />
    </f7-block>
    <f7-block-title>{{ $t('relationsStatus') }}</f7-block-title>
    <f7-block strong>
      <pie-chart :data="relationsStatus" :donut="true" legend="right" :colors="['#4caf50', '#ff9800', '#f44336', 'gray']" height="200px" />
    </f7-block>
  </f7-page>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';

Vue.use(Chartkick.use(Chart));

export default {
  data() {
    return {
      statsMonth: [],
      relationsStatus: [],
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['contacts', 'stats']),
  },
  mounted() {
    this.$store.dispatch('getStatsMonth')
      .then((statsMonth) => {
        this.statsMonth = statsMonth;
      });
    const relations = {
      good: 0, warning: 0, danger: 0, frozen: 0,
    };
    this.contacts.forEach((contact) => {
      relations[contact.status] += 1;
    });
    this.relationsStatus = [
      [this.$t('relationsStatus-good'), relations.good],
      [this.$t('relationsStatus-warning'), relations.warning],
      [this.$t('relationsStatus-danger'), relations.danger],
      [this.$t('relationsStatus-frozen'), relations.frozen],
    ];
  },
};
</script>

<style lang="less" scoped>
.userStats {
  text-align: center;

  h1 {
    margin: 0;
    font-size: 3em;
  }
  p {
    margin: 0;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Statistics",
    "contacts": "Contacts",
    "interactions": "Interactions",
    "memos": "Memos",
    "month-evolution": "Monthly activity",
    "relationsStatus": "Relations status",
    "relationsStatus-danger": "Late",
    "relationsStatus-frozen": "Frozen",
    "relationsStatus-good": "Up to date",
    "relationsStatus-warning": "Contact now"
  },
  "fr": {
    "title": "Statistiques",
    "contacts": "Contacts",
    "interactions": "Interactions",
    "memos": "Mémos",
    "month-evolution": "Activité par mois",
    "relationsStatus": "État des relations",
    "relationsStatus-danger": "En retard",
    "relationsStatus-frozen": "Gelé",
    "relationsStatus-good": "A jour",
    "relationsStatus-warning": "A contacter"
  }
}
</i18n>
