<template>
  <f7-list-item
    ref="frequency"
    smart-select
    :smart-select-params="{closeOnSelect: true}"
    :title="$t('frequency.title')"
  >
    <select
      name="frequency"
      class="select-frequency"
      :value="normalizedValue"
      @change="updateValue($event.target.value)"
    >
      <option
        :value="1"
        :data-display-as="$t('frequency.day')"
      >
        {{ $t('frequency.everyDay') }}
      </option>
      <option
        :value="7"
        :data-display-as="$t('frequency.week')"
      >
        {{ $t('frequency.everyWeek') }}
      </option>
      <option
        :value="15"
        :data-display-as="$t('frequency.fortnight')"
      >
        {{ $t('frequency.everyFortnight') }}
      </option>
      <option
        :value="30"
        :data-display-as="$t('frequency.month')"
      >
        {{ $t('frequency.everyMonth') }}
      </option>
      <option
        :value="90"
        :data-display-as="$t('frequency.trimester')"
      >
        {{ $t('frequency.everyTrimester') }}
      </option>
      <option
        :value="180"
        :data-display-as="$t('frequency.semester')"
      >
        {{ $t('frequency.everySemester') }}
      </option>
      <option
        :value="365"
        :data-display-as="$t('frequency.year')"
      >
        {{ $t('frequency.everyYear') }}
      </option>
      <option
        :value="0"
        :data-display-as="$t('frequency.frozen')"
      >
        {{ $t('frequency.everyFrozen') }}
      </option>
    </select>
  </f7-list-item>
</template>

<script>
import Contact from '../class/contact.class';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Number,
      default: 90,
    },
  },
  data() {
    return {
      // normalizedValue: this.normalizeValue(this.value),
    };
  },
  computed: {
    normalizedValue() {
      return Contact.normalizeFrequency(this.value);
    },
  },
  watch: {
    normalizedValue(newValue) {
      this.$refs.frequency.f7SmartSelect.setValue(
        newValue,
      );
    },
  },
  methods: {
    updateValue(v) {
      this.$emit('change', Contact.normalizeFrequency(v));
    },
  },
};
</script>
