// Make a date from a JSON string, a Firebase Timestamp, or a simple Date
export function makeDate(value) {
  if (!value) return null;
  // If Firestore Timestamp
  if (typeof value.toDate === 'function') return value.toDate();
  // If Firestore Timestamp parsed from JSON
  if (value._seconds) return new Date(value._seconds * 1000 + (value._nanoseconds || 0) / 1000000);
  // If Date
  if (value instanceof Date) return value;
  // If we hope it's something parsable by Date
  const date = new Date(value);
  return Number.isNaN(date.getTime()) ? null : date;
}

export const supportedVersion = '2.0.0';
/*
CHANGELOG
v2.0.0:
  - "interactions" renamed to "events"
  - new "memo" type
  - "type" becomes "via"
  - "minFrequency" renamed to "frequency"
  - "_latency" to "latency"
  - stats for memos
  - version support
*/

export default {
  makeDate,
  supportedVersion,
};
