<template>
  <f7-card v-bind="$attrs" :class="{ 'memo': event.isMemo, 'reminder': event.isReminder }">
    <f7-card-header>
      {{ date }}
    </f7-card-header>
    <f7-card-content>
      <event-header :event="event" />
      <div v-if="notes" class="notes" @click="navigate" v-html="notes" />
      <div v-else class="notes no-notes" @click="navigate">
        {{ $t('no-notes') }}
      </div>
    </f7-card-content>
    <f7-card-footer v-if="event.isReminder">
      <f7-button
        icon-ios="f7:bell_slash_fill"
        icon-aurora="f7:bell_slash_fill"
        icon-md="material:notifications_off"
        :tooltip="$t('action.freeze')"
        @click="freezeContact(event)"
      />
      <f7-button fill :href="'/p/event-new/?contact='+event.contactId">
        {{ $t('reminder-done') }}
      </f7-button>
      <f7-button
        icon-ios="f7:goforward_plus"
        icon-aurora="f7:goforward_plus"
        icon-md="material:update"
        :tooltip="$t('action.postpone')"
        @click="postpone(event)"
      />
    </f7-card-footer>
    <f7-card-footer v-else>
      <f7-button
        icon-ios="f7:trash_fill"
        icon-aurora="f7:trash_fill"
        icon-md="material:delete"
        :tooltip="$t('action.delete')"
        @click="deleteEvent(event)"
      />
      <f7-button v-if="event.isMemo" fill @click="toggleCompleted(event)">
        {{ event.completed ? $t('memo-not-completed') : $t('memo-completed') }}
      </f7-button>
      <f7-button v-if="event.isInteraction" fill @click="toggleCompleted(event)">
        {{ event.completed ? $t('interaction-not-completed') : $t('interaction-completed') }}
      </f7-button>
      <f7-button
        icon-ios="f7:goforward_plus"
        icon-aurora="f7:goforward_plus"
        icon-md="material:update"
        :tooltip="$t('action.postpone')"
        :disabled="!postponable"
        @click="postpone(event)"
      />
    </f7-card-footer>
  </f7-card>
</template>

<script>
import Moment from 'moment';
import Interaction from '../class/interaction.class';
import EventHeader from './event-header.vue';
import DBEvent from '../class/dbEvent.class';
import MixinEvent from '../mixins/event';

export default {
  components: { EventHeader },
  mixins: [MixinEvent],
  props: {
    event: {
      type: DBEvent,
      default: () => new Interaction(),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      link: false,
      date: null,
      notes: null,
      postponable: false,
    };
  },
  mounted() {
    // Date
    const isToday = this.event.date.toDateString() === new Date().toDateString();
    if (this.event.isReminder && this.event.isFirstReminder) this.date = this.$t('date-firstReminder');
    else this.date = isToday ? this.$t('date-today') : Moment(this.event.date).fromNow();
    this.postponable = isToday || this.event.date < new Date();
    // Link
    if (this.event.isReminder) this.link = `/contact/${this.event.contacts[0]}/`;
    else this.link = `/event/${this.event.id}/`;
    // Notes
    this.notes = this.event.notesHtml;
  },
  methods: {
    navigate(event) {
      // We navigate only if the click was not on a link inside the notes
      if (event.target.tagName.toLowerCase() !== 'a') {
        this.$f7router.navigate(this.link);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  display: flex;
  flex-direction: column;
}
.card-header {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  min-height: auto;
  font-size: 0.7em;
  font-weight: bold;
  justify-content: flex-end;
  color: gray;
  background: #fafafa;
  flex-shrink: 0;
}
.card-content {
  flex-grow: 1;
  overflow: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.card-footer {
  background: #fafafa;
  flex-shrink: 0;
}
.notes {
  flex-grow: 1;
}
.no-notes {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.list /deep/  ul::after {
  display: block !important;
}
.accordion-list .list /deep/  ul::after {
  display: none !important;
}
.card .list, .card .accordion-list {
  margin-bottom: var(--f7-card-content-padding-vertical);
}
.card .accordion-list .list {
  margin-bottom: 0;
}
.card p {
  margin: 0;
}
.card .no-notes {
  text-align: center;
  color: gray;
  font-style: italic;
  font-size: 0.8em;
}
.memo {
  .card-content {
    background: var(--event-bg-color-memo);
  }
  .card-header, .card-footer {
    background: var(--event-bg-color-memo-light);
  }
}
</style>

<i18n>
{
  "en": {
    "date-today": "Today",
    "date-firstReminder": "Never contacted",
    "interaction-completed": "Completed?",
    "interaction-not-completed": "Not completed?",
    "memo-completed": "Done?",
    "memo-not-completed": "Not done?",
    "reminder-done": "Contacted?",
    "no-notes": "Click to add notes"
  },
  "fr": {
    "date-today": "Aujourd'hui",
    "date-firstReminder": "Jamais contacté",
    "interaction-completed": "Complétée ?",
    "interaction-not-completed": "A compléter ?",
    "memo-completed": "Traité ?",
    "memo-not-completed": "A traiter ?",
    "reminder-done": "Contacté ?",
    "no-notes": "Cliquer pour ajouter des notes"
  }
}
</i18n>
