import Vue from 'vue';
import Vuex from 'vuex';
import firebase from './firebase-config'; // REDUCE SIZE

import userStore from './store-user';
import dbStore from './store-db';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    userStore,
    dbStore,
  },
  actions: {
    loadStore({ commit, dispatch }, { user }) {
      commit('setUser', user);
      dispatch('bindContacts');
      dispatch('bindEvents');
      dispatch('bindUserDoc');
    },
    clearStore({ commit, dispatch }) {
      dispatch('unbindContacts');
      dispatch('unbindEvents');
      dispatch('unbindUserDoc');
      commit('setUser', null);
    },
    enableAnalytics(context, enabled) {
      // If AppCenter has been attached to window (=> running on cordova)
      if (window.AppCenter) {
        // We enable the analytics
        window.AppCenter.Analytics.setEnabled(enabled, () => { /* success */ }, (error) => {
          console.error('AppCenter analytic failed', error);
        });
      }
      // If Plugin Firbease Analytic has been attached to window (=> running on cordova)
      if (window.cordova && window.cordova.plugins.firebase.analytics) {
        // We enable the analytics
        window.cordova.plugins.firebase.analytics.setEnabled(enabled);
      // Else, if we are not on cordova
      } else {
        firebase.analytics().setAnalyticsCollectionEnabled(enabled);
      }
    },
    trackEvent(context, { name, properties }) {
      // Analytics with AppCenter (only Cordova)
      if (window.AppCenter) {
        window.AppCenter.Analytics.isEnabled(() => {
          console.log('track AppCenter event', name);
          window.AppCenter.Analytics.trackEvent(name, properties, null, (error) => {
            console.error('Track Event AppCenter trackEvent', error);
          });
        }, (error) => {
          console.error('Track Event AppCenter isEnabled', error);
        });
      }
      // Analytics with Google on Cordova
      if (window.cordova && window.cordova.plugins.firebase.analytics) {
        console.log('track Google Analytic Cordova event', name);
        window.cordova.plugins.firebase.analytics.logEvent(name, properties);
      // Analytics with Google on Web
      } else {
        console.log('track Google Analytic event', name);
        firebase.analytics().logEvent(name, properties);
      }
    },
    trackPage(context, path) {
      // We extract a page name from the path (the first non null page that is not p)
      const page = path.split('/').find(x => x && x !== 'p') || 'UNKNOWN';
      const analytics = window.cordova && window.cordova.plugins.firebase.analytics ? window.cordova.plugins.firebase.analytics : firebase.analytics();
      if (analytics) {
        analytics.setCurrentScreen(page);
        analytics.logEvent('screen_view', { page_location: path, page_title: page });
        console.log('track page', page, path);
      }
    },
  },
});

// handle page reload
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch('loadStore', { user });
  } else {
    store.dispatch('clearStore');
  }
});

export default store;
