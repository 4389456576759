<template>
  <f7-page name="login-signup" login-screen>
    <f7-navbar>
      <f7-nav-left v-if="$f7route.query.showNavbar">
        <f7-nav-left :back-link="$t('common.back')" />
      </f7-nav-left>
      <f7-nav-title>Kipinto</f7-nav-title>
    </f7-navbar>
    <!-- <f7-login-screen-title>Join Kipinto</f7-login-screen-title> -->
    <f7-segmented large strong>
      <f7-button :active="showLogin" @click="mode = 'login'">
        {{ $t('login') }}
      </f7-button>
      <f7-button :active="showSignup" @click="mode = 'signup'">
        {{ $t('signup') }}
      </f7-button>
    </f7-segmented>
    <f7-list v-show="showSignup" no-hairlines-between class="consent">
      <f7-list-item checkbox value="tos" :checked="consent.tos" @change="checkConsent">
        <span v-html="$t('consent-tos')" />
      </f7-list-item>
      <f7-list-item checkbox value="privacy" :checked="consent.privacy" @change="checkConsent">
        <span v-html="$t('consent-privacy')" />
      </f7-list-item>
    </f7-list>
    <fieldset class="no-padding-top">
      <legend>{{ $t('with-email') }}</legend>
      <f7-list form @submit="submitForm">
        <f7-list-input
          type="email"
          autocomplete="email"
          :placeholder="$t('email-placeholder')"
          clear-button
          :value="email"
          @input="email = $event.target.value"
        />
        <f7-list-input
          type="password"
          :autocomplete="showLogin ? 'current-password' : 'new-password'"
          :placeholder="$t('password-placeholder')"
          clear-button
          :value="password"
          @input="password = $event.target.value"
        />
        <f7-list-item-row v-show="showSignup" class="no-padding-bottom">
          <f7-list-item-cell>
            <f7-button type="submit" large fill>
              {{ $t('signup') }}
            </f7-button>
          </f7-list-item-cell>
        </f7-list-item-row>
        <f7-list-item-row v-show="showLogin">
          <f7-list-item-cell>
            <f7-button type="submit" large fill>
              {{ $t('login') }}
            </f7-button>
          </f7-list-item-cell>
        </f7-list-item-row>
        <f7-list-item-row v-show="showLogin" class="no-padding-bottom">
          <f7-list-item-cell>
            <f7-button small @click="resetPassword">
              {{ $t('resetPassword') }}
            </f7-button>
          </f7-list-item-cell>
        </f7-list-item-row>
      </f7-list>
    </fieldset>
    <fieldset>
      <legend>{{ $t('with-provider') }}</legend>
      <f7-row>
        <f7-col v-for="provider in providers" :key="provider.id">
          <f7-button
            large
            :disabled="!provider.available"
            :tooltip="provider.name"
            :icon-f7="provider.icon"
            class="provider"
            @click="connect(provider.id)"
          />
        </f7-col>
      </f7-row>
    </fieldset>
    <fieldset v-show="showSignup">
      <legend>{{ $t('with-anonymous') }}</legend>
      <f7-button large block @click="anonymousLogin">
        {{ $t('testWithoutAccount') }}
      </f7-button>
    </fieldset>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      mode: this.$f7route.query.mode || 'login',
      email: '',
      password: '',
      consent: {
        tos: false,
        privacy: false,
      },
      providers: [
        {
          id: 'google.com', name: 'Google', icon: 'logo_google', available: true,
        },
        {
          id: 'facebook.com', name: 'Facebook', icon: 'logo_facebook', available: false,
        },
        {
          id: 'twitter.com', name: 'Twitter', icon: 'logo_twitter', available: false,
        },
        {
          id: 'linkedin.com', name: 'Linkedin', icon: 'logo_linkedin', available: false,
        },
        {
          id: 'apple.com', name: 'Apple', icon: 'logo_apple', available: false,
        },
      ],
    };
  },
  computed: {
    showSignup() { return this.mode === 'signup'; },
    showLogin() { return this.mode === 'login'; },
  },
  methods: {
    checkConsent(event) {
      const value = event.target.value;
      this.consent[value] = event.target.checked;
    },
    consentCheckedBeforeSignup() {
      if (!this.consent.tos) {
        this.$f7.dialog.alert(this.$t('consent-tos-required'));
        return false;
      }
      if (!this.consent.privacy) {
        this.$f7.dialog.alert(this.$t('consent-privacy-required'));
        return false;
      }
      return true;
    },
    submitForm(e) {
      if (this.mode === 'signup') this.connect('signup');
      if (this.mode === 'login') this.connect('login');
      e.preventDefault();
    },
    async connect(provider) {
      try {
        if (this.mode === 'signup' && !this.consentCheckedBeforeSignup()) return;
        switch (provider) {
          case 'signup':
            await this.$store.dispatch('signUserUp', {
              email: this.email,
              password: this.password,
            });
            break;
          case 'login':
            await this.$store.dispatch('signUserIn', {
              email: this.email,
              password: this.password,
            });
            break;
          case 'google.com':
            await this.$store.dispatch('signUserInGoogle');
            break;
          case 'anonymous':
            await this.$store.dispatch('signUserAnonymously');
            break;
          default: break;
        }
      } catch (e) {
        this.$f7.dialog.alert(this.$t('connection-error', { err: e.message }));
      }
      this.password = null; // We reset the password
    },
    anonymousLogin() {
      this.$f7.dialog.confirm(
        this.$t('testAccount-explanation'),
        this.$t('testAccount-create'),
        () => {
          this.connect('anonymous');
        },
      );
    },
    resetPassword() {
      if (!this.email) {
        this.$f7.dialog.prompt('Quel est votre email?', (email) => {
          this.email = email;
          this.resetPassword();
        });
      } else {
        this.$store.dispatch('resetPassword', this.email)
          .then(() => this.$f7.dialog.alert(this.$t('passwordReset-success')))
          .catch(err => this.$f7.dialog.alert(`Oopsie in ResetPassword. ${err.message}`));
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .page-content {
  padding-left: var(--f7-block-padding-horizontal);
  padding-right: var(--f7-block-padding-horizontal);
  --f7-login-screen-blocks-max-width: 350px;
}

.segmented {
  margin: var(--f7-login-screen-blocks-margin-vertical) auto;
  max-width: var(--f7-login-screen-blocks-max-width);
}

.consent {

  /deep/ .item-content {
    min-height: 0;

    .item-inner {
      padding-top: 0.2em;
      padding-bottom: 0.2em;
      padding-right: 0;
      min-height: 0;
    }
  }

  /deep/ label.item-checkbox > .icon-checkbox {
    margin-right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));
  }
}

p.tos {
  font-size: 0.8em;
  margin: var(--f7-login-screen-blocks-margin-vertical) auto;
  max-width: var(--f7-login-screen-blocks-max-width);
  text-align: center;
}

fieldset {
  border: 1px solid var(--f7-list-item-border-color);
  border-radius: calc(var(--f7-button-border-radius) + 2px);
  max-width: var(--f7-login-screen-blocks-max-width);
  margin: var(--f7-login-screen-blocks-margin-vertical) auto;
  padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
  padding-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right));
  padding-top: calc(var(--f7-block-padding-vertical) / 2);
  padding-bottom: var(--f7-block-padding-vertical);
  box-sizing: border-box;

  /deep/ .list .item-input, /deep/ .list .item-inner {
    padding-left: 0;
    padding-right: 0;
  }

  /deep/ .item-row {
    padding: var(--f7-list-item-padding-vertical) 0;
  }

  .list, .block {
    margin: 0;
  }

  .button.provider {
    text-overflow: clip;
  }
}

legend {
  font-size: 0.9em;
  color: var(--f7-input-placeholder-color);
  // margin-left: var(--f7-block-padding-horizontal);
}

/*
.separator {
  overflow: hidden;
  text-align: center;
  width: 250px;
  margin: auto;
  font-size: 0.9em;
  color: var(--f7-input-placeholder-color);

  &:before,
  &:after {
    background-color: var(--f7-list-item-border-color);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 0.5em;
    margin-left: -50%;
  }
  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
*/
</style>

<i18n>
{
  "en": {
    "passwordReset-success": "You received an email with a link to renew your password.",
    "connection-error": "An error occured: {err}",
    "login": "Login",
    "signup": "Signup",
    "consent-tos": "I agree to the <a href='/p/legals/?tab=tos'>Terms of Services</a>",
    "consent-privacy": "I understand the <a href='/p/legals/?tab=privacy'>Privacy Policy</a>",
    "consent-tos-required": "You must confirm that you accept the TOS",
    "consent-privacy-required": "You must confirm that you understand the privacy policy",
    "with-email": "With an email & password",
    "with-provider": "With a service provider",
    "with-anonymous": "With a temporary account",
    "email-placeholder": "Your email",
    "password-placeholder": "Your password",
    "resetPassword": "Password forgotten ?",
    "testWithoutAccount": "Test without creating an account",
    "testAccount-create": "Create test account",
    "testAccount-explanation": "<p><strong>We cannot guarante the durability of test account data.</strong></p><p>To retrieve your data from any device, you can convert your test account into a permanent account at any time.</p>"
  },
  "fr": {
    "passwordReset-success": "Vous avez reçu un email avec un lien pour renouveller votre mot de passe.",
    "connection-error": "Une erreur est survenue : {err}",
    "login": "Se connecter",
    "signup": "S'inscrire",
    "consent-tos": "J'accepte les <a href='/p/legals/?tab=tos'>CGUs</a>",
    "consent-privacy": "J'ai lu la <a href='/p/legals/?tab=privacy'>Politique de Confidentialité</a>",
    "consent-tos-required": "Vous devez confirmer que vous acceptez les CGU",
    "consent-privacy-required": "Vous devez confirmer que vous avez lu la politique de confidentialité",
    "with-email": "Avec un email & un mot de passe",
    "with-provider": "Avec un fournisseur de services",
    "with-anonymous": "Avec un compte temporaire",
    "email-placeholder": "Votre email",
    "password-placeholder": "Votre mot de passe",
    "resetPassword": "Mot de passe oublié ?",
    "testWithoutAccount": "Tester sans créer de compte",
    "testAccount-create": "Créer un compte de test",
    "testAccount-explanation": "<p><strong>Nous ne pouvons garantir la pérennité des données des comptes de test.</strong></p><p>Pour retrouver vos données depuis n'importe quel appareil, vous pourrez à tout moment convertir votre compte de test en compte permanent.</p>"
  }
}
</i18n>
