<template>
  <f7-page name="account-reauth">
    <f7-navbar>
      <f7-nav-title>{{ $t("title") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t("common-close") }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block strong>
      {{ $t("explain") }}
    </f7-block>
    <template v-if="providers.includes('password')">
      <hr>
      <f7-list form>
        <f7-list-input
          type="email"
          autocomplete="email"
          placeholder="Your e-mail"
          clear-button
          :value="email"
          @input="email = $event.target.value"
        />
        <f7-list-input
          type="password"
          autocomplete="current-password"
          placeholder="Your password"
          clear-button
          :value="password"
          @input="password = $event.target.value"
        />
        <f7-list-button
          title="Reauth"
          large
          @click="reauthWithEmailPassword"
        />
      </f7-list>
    </template>
    <template v-if="providers.includes('google.com')">
      <hr>
      <f7-block>
        <f7-button
          fill
          block
          raised
          large
          color="red"
          @click="reauthWithGoogle"
        >
          Reauth with Google
        </f7-button>
      </f7-block>
    </template>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      providers: [],
    };
  },
  mounted() {
    if (this.$store.getters.user) {
      this.$store.getters.user.providerData.forEach((provider) => {
        this.providers.push(provider.providerId);
      });
    }
  },
  methods: {
    reauthWithEmailPassword() {
      this.$store
        .dispatch('reauthWithEmailPassword', {
          email: this.email,
          password: this.password,
        })
        .then(() => this.reauthSuccessful())
        .catch(error => this.$f7.dialog.alert(`Oopsie in reauth Email. ${error.message}`));
    },
    reauthWithGoogle() {
      this.$store
        .dispatch('reauthWithGoogle')
        .then(() => this.reauthSuccessful())
        .catch(error => this.$f7.dialog.alert(`Oopsie in Google reauth. ${error.message}`));
    },
    reauthSuccessful() {
      this.$f7.popup.close();
      this.$f7.toast.show({ text: this.$t('reauthSuccessful') });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Re-authentication",
    "explain": "The action you attempted is sensitive and requires that you have recently signed in. Please re-authenticate.",
    "reauthSuccessful": "Reauthentication successful! You can retry your previous action."
  },
  "fr": {
    "title": "Réauthentification",
    "explain": "L'action que vous avez voulu réaliser est sensible et nécessite que vous vous soyez connecté récemment. Veuillez vous réauthentifier.",
    "reauthSuccessful": "Réauthentification réussie! Vous pouvez réessayer votre action précédente."
    }
}
</i18n>
