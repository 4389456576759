<template>
  <f7-page name="account-permanentify">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-list form>
      <f7-list-input
        type="email"
        autocomplete="email"
        placeholder="Your e-mail"
        clear-button
        :value="email"
        @input="email = $event.target.value"
      />
      <f7-list-input
        type="password"
        autocomplete="current-password"
        placeholder="Your password"
        clear-button
        :value="password"
        @input="password = $event.target.value"
      />
      <f7-list-button title="Sign-up" large @click="linkWithEmailPassword" />
    </f7-list>
    <hr>
    <f7-block>
      <f7-button
        fill
        block
        raised
        large
        color="red"
        @click="linkWithGoogle"
      >
        Use Google Auth
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    linkWithEmailPassword() {
      this.$store.dispatch('linkWithEmailPassword', {
        email: this.email,
        password: this.password,
      })
        .then(() => {
          this.$f7.popup.close();
          this.$f7.dialog.alert(
            this.$t('signup-verifyEmail'),
            this.$t('signup-successful'),
          );
        })
        .catch(error => this.$f7.dialog.alert(`Oopsie in linking Email. ${error.message}`));
    },
    linkWithGoogle() {
      this.$store.dispatch('linkWithGoogle')
        .then(() => this.$f7.popup.close())
        .catch(error => this.$f7.dialog.alert(`Oopsie in GoogleLogin. ${error.message}`));
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Create the account",
    "signup-successful": "Signup Successful!",
    "signup-verifyEmail": "You received an email to verify your adress. Remember to click on the link it contains!"
    },
  "fr": {
    "title": "Créer un compte",
    "signup-successful": "Inscription réussie !",
    "signup-verifyEmail": "Vous avez reçu un email pour vérifier votre adresse. Pensez à cliquer sur le lien qu'il contient !"
    }
}
</i18n>
