<template>
  <f7-page name="event-edit" class="page-event-edit" :class="{ memo: event.isMemo }">
    <f7-navbar>
      <f7-nav-left>
        <f7-link popup-close>
          {{ $t('common.cancel') }}
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ title }}</f7-nav-title>
      <f7-nav-right>
        <f7-link @click="saveEvent">
          {{ $t('common.ok') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list inline-labels no-hairlines-md inset form>
      <f7-list-item
        ref="selectContacts"
        smart-select
        :smart-select-params="{ searchbar: true }"
        :title="$t('form-contacts')"
      >
        <select
          name="contacts"
          multiple
          @change="selectContacts"
        >
          <option
            v-for="contact in contacts"
            :key="contact.id"
            :value="contact.id"
          >
            {{ contact.name }}
          </option>
        </select>
      </f7-list-item>
      <f7-list-input
        :label="$t('form-date')"
        type="datepicker"
        :placeholder="$t('form-date')"
        :value="[event.date]"
        :calendar-params="{closeOnSelect: true, locale: this.$root.$i18n.locale }"
        @calendar:change="dates => event.date = dates[0]"
      />
      <f7-list-input
        :label="$t('form-type')"
        :input="false"
        class="input-type"
      >
        <f7-segmented slot="input" strong>
          <f7-button
            :active="event.isMemo"
            class="tooltip-init"
            data-tooltip="Memo"
            @click="switchEventTo('memo')"
          >
            <event-icon icon="memo" />
          </f7-button>
          <f7-button
            :active="event.isInteraction && event.via === 'email'"
            class="tooltip-init"
            data-tooltip="Email or letter"
            @click="switchEventTo('email')"
          >
            <event-icon icon="email" />
          </f7-button>
          <f7-button
            :active="event.isInteraction && event.via === 'chat'"
            class="tooltip-init"
            data-tooltip="Text conversation"
            @click="switchEventTo('chat')"
          >
            <event-icon icon="chat" />
          </f7-button>
          <f7-button
            :active="event.isInteraction && event.via === 'call'"
            class="tooltip-init"
            data-tooltip="Phone call"
            @click="switchEventTo('call')"
          >
            <event-icon icon="call" />
          </f7-button>
          <f7-button
            :active="event.isInteraction && event.via === 'meeting'"
            class="tooltip-init"
            data-tooltip="In person meeting"
            @click="switchEventTo('meeting')"
          >
            <event-icon icon="meeting" />
          </f7-button>
        </f7-segmented>
      </f7-list-input>
    </f7-list>

    <!-- <f7-block-title>{{ $t('form-notes') }}</f7-block-title> -->
    <f7-list no-hairlines-md inset>
      <notes-editor
        slot="list"
        class="event-notes"
        :object="event"
        @change="(value) => formNotes = value"
      />
      <!--
          <f7-list-input
            type="textarea"
            class="interaction-description"
            :value="interaction.notes"
            :placeholder="$t('interaction-notes-placeholder')"
            resizable
            @input="interaction.notes = $event.target.value"
          />
          -->
      <!-- <f7-list-item title="Go to Notebook" :link="'/notebook/'+$f7route.params.contactName+'/'" /> -->
      <f7-block-footer slot="after-list">
        {{ event.isInteraction ? $t('form-notes-explanation-interaction') : $t('form-notes-explanation-memo') }}
      </f7-block-footer>
    </f7-list>

    <f7-list inset form>
      <f7-list-item>
        <span>{{ event.isInteraction ? $t('form-completion-interaction') : $t('form-completion-memo') }}</span>
        <f7-toggle
          :checked="event.completed && event.date <= new Date()"
          :disabled="event.date > new Date()"
          @toggle:change="event.completed = $event"
        />
      </f7-list-item>
      <f7-block-footer>{{ event.isInteraction ? $t('form-completion-explanation-interaction') : $t('form-completion-explanation-memo') }}</f7-block-footer>
    </f7-list>

    <f7-block v-if="$f7route.params.eventId">
      <f7-button block color="red" @click="deleteEvent">
        {{ event.isInteraction ? $t('delete-interaction') : $t('delete-memo') }}
      </f7-button>
    </f7-block>
  </f7-page>
</template>

<style>
</style>


<script>
import { mapGetters } from 'vuex';
import Interaction from '../class/interaction.class';
import Memo from '../class/memo.class';
import NotesEditor from '../components/notes-editor.vue';
import EventIcon from '../components/event-icon.vue';

export default {
  components: { NotesEditor, EventIcon },
  data() {
    return {
      event: new Interaction(),
      formNotes: '',
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['contacts']),
    title() {
      if (this.event.isMemo) {
        return this.event.id ? this.$t('title-edit-memo') : this.$t('title-new-memo');
      }
      return this.event.id ? this.$t('title-edit-interaction') : this.$t('title-new-interaction');
    },
  },
  mounted() {
    const eventId = this.$f7route.params.eventId;
    if (eventId) {
      const event = this.$store.getters.eventById(eventId);
      if (event) {
        if (event.isInteraction) this.event = new Interaction(event, eventId);
        if (event.isMemo) this.event = new Memo(event, eventId);
        this.$refs.selectContacts.f7SmartSelect.setValue(this.event.contacts);
      }
    } else {
      if (this.$f7route.query.type) {
        if (this.$f7route.query.type === 'memo') this.event = new Memo();
        else {
          this.event = new Interaction();
          this.event.via = this.$f7route.query.type;
        }
      }
      if (this.$f7route.query.contact) {
        this.event.contacts = [this.$f7route.query.contact];
        this.$refs.selectContacts.f7SmartSelect.setValue(this.event.contacts);
      }
    }
  },
  methods: {
    selectContacts(select) {
      this.event.contacts = [...select.target].filter(option => option.selected).map(option => option.value);
    },
    switchEventTo(type) {
      if (type === 'memo') {
        if (this.event.isMemo) return;
        this.event = new Memo(this.event, this.event.id);
      } else {
        if (this.event.isMemo) this.event = new Interaction(this.event, this.event.id);
        this.event.via = type;
      }
    },
    saveEvent() {
      // We attach the form notes to the event
      this.event.notes = this.formNotes;
      // If we have the required information, we attempt to save the event
      if (this.event.isReadyToExportToDB()) {
        // We save the event
        this.$store.dispatch('saveEvent', this.event)
          .catch((error) => {
            // We raise an alert to the user
            console.error('Error creating the event: ', error);
            this.$f7.dialog.alert(this.$t('save-failed-firebase'));
          });
        // We close the "event edit" page
        this.$f7.popup.close();
      } else {
        this.$f7.dialog.alert(this.$t('save-failed-missInformation'));
      }
    },
    deleteEvent() {
      const _this = this;
      _this.$f7.dialog.confirm(
        _this.event.isInteraction ? _this.$t('delete-confirm-interaction') : _this.$t('delete-confirm-memo'),
        () => {
          console.log('Start deleting the event');
          // We delete the interaction
          _this.$store.dispatch('deleteEvent', _this.event)
            .catch((error) => {
              // We raise an alert to the user
              console.error('Error deleting event: ', error);
              _this.$f7.dialog.alert(_this.$t('delete-failed'));
            });
          // We close the popup
          _this.$f7.popup.close();
        },
      );
    },
  },
};
</script>

<style lang="less">
.page-event-edit {
  transition: background-color 1s;

  &.memo {
    background-color: var(--event-bg-color-memo-light);
  }

  .event-feedback textarea.resizable,
  .event-description textarea.resizable {
    min-height: 100px;
  }
  input[type="date"] {
    text-align: right !important;
  }
  .inline-labels .item-label {
    width: 25%;
  }

  .input-type .item-input:not(.item-input-outline) .item-input-wrap:after {
    display: none;
  }
  .input-type .icon {
    font-size: var(--f7-inline-label-font-size);
  }
  .input-type .segmented.segmented-strong:before {
    content: '';
    width: 1px;
    background: white;
    position: absolute;
    left: 20%;
    top: 0;
    bottom: 0;
  }
  /* CSS for Round Buttons
  .type-buttons.segmented {
    justify-content: flex-end;

    .button.button-outline {
    width: var(--f7-button-height);
    border-radius: 50%;
    border-width: var(--f7-button-border-width);
    border-left: var(--f7-button-border-width, 0px) solid var(--f7-button-border-color, var(--f7-theme-color));
    margin-left: 0.5em;
    padding: 0;

    .icon {
    font-size: calc(var(--f7-button-height) / 2);
    line-height: 0.75;
    }
    }
  }
  */

}
</style>

<i18n>
{
  "en": {
    "title-edit-interaction": "Edit Interaction",
    "title-new-interaction": "New Interaction",
    "title-edit-memo": "Edit Memo",
    "title-new-memo": "New Memo",
    "form-contacts": "Contacts",
    "form-contacts-none": "None",
    "form-date": "Date",
    "form-type": "Type",
    "form-notes": "Notes",
    "form-notes-explanation-interaction": "Take notes before to prepare & after to remember. The first line will be used as title.",
    "form-notes-explanation-memo": "Take notes of things you want to do or remember. The first line will be used as title.",
    "form-completion-interaction": "Interaction completed",
    "form-completion-memo": "Memo done",
    "form-completion-explanation-interaction": "Report that the interaction is complete once it has occurred and you have written the report.",
    "form-completion-explanation-memo": "Memos are not included when computing the status of the contact.",
    "save-failed-firebase": "Error when attempting to save. Please retry.",
    "save-failed-missInformation": "You must select at least one contact, and chose a date.",
    "delete-interaction": "Delete this interaction",
    "delete-confirm-interaction": "Are you sure you want to permanently delete this interaction?",
    "delete-memo": "Delete this memo",
    "delete-confirm-memo": "Are you sure you want to permanently delete this memo?",
    "delete-failed": "The deletion failed. Please retry."
  },
  "fr": {
    "title-edit-interaction": "Modifier l'Interaction",
    "title-new-interaction": "Nouvelle Interaction",
    "title-edit-memo": "Modifier le Mémo",
    "title-new-memo": "Nouveau Mémo",
    "form-contacts": "Contacts",
    "form-contacts-none": "Aucun",
    "form-date": "Date",
    "form-type": "Type",
    "form-notes": "Notes",
    "form-notes-explanation-interaction": "Prenez des notes avant pour vous préparer & après pour vous souvenir. La première ligne servira de titre.",
    "form-notes-explanation-memo": "Prenez des notes sur ce que vous souhaitez faire et / ou retenir. La première ligne servira de titre.",
    "form-completion-interaction": "Interaction complétée",
    "form-completion-memo": "Mémo traité",
    "form-completion-explanation-interaction": "Signalez que l'interaction est complétée une fois qu'elle a eu lieu et que vous en avez rédigé le compte-rendu.",
    "form-completion-explanation-memo": "Un mémo n'est pas une interaction et n'influence pas le rythme des rappels.",
    "save-failed-firebase": "Une erreur est survenue pendant la sauvegarde. Veuillez réessayez.",
    "save-failed-missInformation": "Vous devez sélectionner au moins 1 contact, ainsi qu'une date.",
    "delete-interaction": "Supprimer cette interaction",
    "delete-confirm-interaction": "Êtes-vous sûr de vouloir supprimer définitivement cette interaction ?",
    "delete-memo": "Supprimer ce mémo",
    "delete-confirm-memo": "Êtes-vous sûr de vouloir supprimer définitivement ce mémo ?",
    "delete-failed": "La suppression a échoué. Veuillez réessayez."
  }
}
</i18n>
