<template>
  <f7-page name="contact">
    <f7-navbar :back-link="backLink">
      <f7-nav-title v-show="titleOpacity !== 0" :style="{ opacity: titleOpacity }" :class="{ invisible: titleOpacity !== ''}">
        {{ contact.name }}
      </f7-nav-title>
      <f7-nav-right>
        <f7-link icon-ios="f7:square_pencil" icon-aurora="f7:square_pencil" icon-md="material:edit" :href="'/p/contact-edit/'+contactId+'/'" />
      </f7-nav-right>
    </f7-navbar>
    <f7-progressbar v-if="contact.hasPendingWrites" infinite class="saving-loader" color="orange" />
    <!-- <span v-if="savingNotes" class="saving-text">saving</span> -->

    <f7-block strong class="header">
      <h1 ref="contactName">
        {{ contact.name }}
      </h1>
      <h2>{{ contact.company }}</h2>
      <f7-list
        accordion-list
        class="info"
      >
        <f7-accordion-item>
          <f7-row class="info-shortcuts" no-gutter>
            <f7-col class="info-toggle-col" />
            <f7-col>
              <f7-link v-if="contact.email" external @click="open('mailto:'+contact.email, $event, 'email')">
                <f7-icon f7="at_circle_fill" class="contact-info-icons" />
              </f7-link>
              <f7-icon v-else f7="at_circle_fill" class="contact-info-icons disabled" color="primary" />
            </f7-col>
            <f7-col>
              <f7-link v-if="phoneCleaned" external @click="open('tel:'+phoneCleaned, $event, 'call')">
                <f7-icon f7="phone_circle_fill" class="contact-info-icons" />
              </f7-link>
              <f7-icon v-else f7="phone_circle_fill" class="contact-info-icons disabled" color="primary" />
            </f7-col>
            <f7-col>
              <f7-link v-if="phoneCleaned" external @click="open('sms:'+phoneCleaned, $event, 'chat')">
                <f7-icon f7="chat_bubble_text_fill" class="contact-info-icons" />
              </f7-link>
              <f7-icon v-else f7="chat_bubble_text_fill" class="contact-info-icons disabled" color="primary" />
            </f7-col>
            <f7-col>
              <f7-link v-if="contact.social.linkedin" :href="'https://www.'+contact.social.linkedin" external target="_blank">
                <f7-icon f7="logo_linkedin" class="contact-info-icons" />
              </f7-link>
              <f7-icon v-else f7="logo_linkedin" class="contact-info-icons disabled" color="primary" />
            </f7-col>
            <f7-col>
              <f7-link v-if="contact.social.facebook" :href="'https://www.'+contact.social.facebook" external target="_blank">
                <f7-icon f7="logo_facebook" class="contact-info-icons" />
              </f7-link>
              <f7-icon v-else f7="logo_facebook" class="contact-info-icons disabled" color="primary" />
            </f7-col>
            <f7-col>
              <f7-link v-if="contact.social.twitter" :href="'https://'+contact.social.twitter" external target="_blank">
                <f7-icon f7="logo_twitter" class="contact-info-icons" />
              </f7-link>
              <f7-icon v-else f7="logo_twitter" class="contact-info-icons disabled" color="primary" />
            </f7-col>
            <f7-col class="info-toggle-col">
              <f7-accordion-toggle
                v-if="contact.email
                  || phoneCleaned
                  || contact.social.linkedin
                  || contact.social.facebook
                  || contact.social.twitter"
              >
                <div class="item-inner" />
              </f7-accordion-toggle>
            </f7-col>
          </f7-row>
          <f7-accordion-content>
            <f7-list class="info-details">
              <f7-list-item
                v-if="contact.email"
                :title="contact.email"
                link
                external
                @click="open('mailto:'+contact.email, $event, 'email')"
              >
                <f7-icon slot="media" f7="at_circle_fill" class="info-icons" color="gray" />
              </f7-list-item>
              <f7-list-item
                v-if="phoneCleaned"
                :title="contact.phone"
                link
                external
                @click="open('tel:'+phoneCleaned, $event, 'call')"
              >
                <f7-icon slot="media" f7="phone_circle_fill" class="info-icons" color="gray" />
              </f7-list-item>
              <f7-list-item
                v-if="phoneCleaned"
                :title="contact.phone"
                link
                external
                @click="open('sms:'+phoneCleaned, $event, 'sms')"
              >
                <f7-icon slot="media" f7="chat_bubble_text_fill" class="info-icons" color="gray" />
              </f7-list-item>
              <f7-list-item
                v-if="contact.social.linkedin"
                :link="'https://www.'+contact.social.linkedin"
                external
                :title="contact.social.linkedin"
                target="_blank"
              >
                <f7-icon slot="media" f7="logo_linkedin" class="info-icons" color="gray" />
              </f7-list-item>
              <f7-list-item
                v-if="contact.social.facebook"
                :link="'https://www.'+contact.social.facebook"
                external
                :title="contact.social.facebook"
                target="_blank"
              >
                <f7-icon slot="media" f7="logo_facebook" class="info-icons" color="gray" />
              </f7-list-item>
              <f7-list-item
                v-if="contact.social.twitter"
                :link="'https://'+contact.social.twitter"
                external
                :title="contact.social.twitter"
                target="_blank"
              >
                <f7-icon slot="media" f7="logo_twitter" class="info-icons" color="gray" />
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-accordion-item>
      </f7-list>
    </f7-block>

    <f7-fab-backdrop slot="fixed" />
    <f7-list accordion inset>
      <f7-list-item accordion-item :title="$t('nextReminder')">
        <f7-badge
          slot="after"
          :color="contact.statusColor"
        >
          {{ nextReminderFromNow || $t('frozen') }}
        </f7-badge>
        <f7-accordion-content>
          <f7-list>
            <f7-list-item
              slot="list"
              :title="$t('lastInteraction')"
              :after="lastInteractionFromNow || $t('noInteraction')"
              :link="linkLastInteraction || '/p/event-new/?contact='+contactId"
            />
            <frequency-selector
              slot="list"
              :value="contact.frequency"
              @change="updateFrequency"
            />
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
    <f7-block-title>
      {{ $t('notes') }}
    </f7-block-title>
    <f7-list inset>
      <notes-editor
        slot="list"
        class="notes"
        :object="contact"
        auto-save
      />
    </f7-list>
    <f7-fab slot="fixed" position="manual">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add" />
      <f7-icon ios="f7:multiply" aurora="f7:multiply" md="material:close" />
      <f7-fab-buttons position="top">
        <f7-fab-button fab-close :label="$t('log-email')" :href="'/p/event-new/?contact='+contactId+'&type=email'">
          <f7-icon ios="f7:envelope_fill" aurora="f7:envelope_fill" md="material:email" />
        </f7-fab-button>
        <f7-fab-button fab-close :label="$t('log-chat')" :href="'/p/event-new/?contact='+contactId+'&type=chat'">
          <f7-icon ios="f7:chat_bubble_text_fill" aurora="f7:chat_bubble_text_fill" md="material:message" />
        </f7-fab-button>
        <f7-fab-button fab-close :label="$t('log-call')" :href="'/p/event-new/?contact='+contactId+'&type=call'">
          <f7-icon ios="f7:phone_fill" aurora="f7:phone_fill" md="material:phone" />
        </f7-fab-button>
        <f7-fab-button fab-close :label="$t('log-meeting')" :href="'/p/event-new/?contact='+contactId+'&type=meeting'">
          <f7-icon ios="f7:person_fill" aurora="f7:person_fill" md="material:person" />
        </f7-fab-button>
      </f7-fab-buttons>
      <f7-fab-buttons position="left">
        <f7-fab-button fab-close :label="$t('log-memo')" :href="'/p/event-new/?contact='+contactId+'&type=memo'">
          <f7-icon ios="f7:doc_text_fill" aurora="f7:doc_text_fill" md="material:description" />
        </f7-fab-button>
      </f7-fab-buttons>
    </f7-fab>
    <f7-block-title>{{ $t('interactionsHistory') }}</f7-block-title>
    <f7-block v-if="eventsOfContact.length === 0">
      {{ $t('noInteractionHistory') }}
    </f7-block>
    <div v-else class="timeline medium-sides">
      <div
        v-for="event in eventsOfContact"
        :key="event.hash"
        class="timeline-item"
      >
        <div class="timeline-item-date">
          {{ Moment(event.date).format('DD MMM') }}<br><small>{{ event.date.getFullYear() }}</small>
        </div>
        <div class="timeline-item-divider" />
        <div class="timeline-item-content">
          <f7-list inset media-list :class="{ memo: event.isMemo, mention: event.isMention }">
            <f7-list-item
              :link="'/event/'+event.id+'/'"
              :subtitle="event.summary.title"
              :text="event.summary.text"
            >
              <div slot="before-title">
                <event-icon :event="event" class="event-icon" /> {{ $t('via.' + event.via) }}
              </div>
            </f7-list-item>
          </f7-list>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import Moment from 'moment';
import Contact from '../class/contact.class';

import FrequencySelector from '../components/frequency-selector.vue';
import EventIcon from '../components/event-icon.vue';
import NotesEditor from '../components/notes-editor.vue';

export default {
  components: {
    FrequencySelector, EventIcon, NotesEditor,
  },
  data() {
    return {
      backLink: this.$f7route.query.back || this.$t('common.back'),
      titleOpacity: 0,
      savingNotes: false,
      contactId: this.$f7route.params.contactId,
      Moment,
    };
  },
  computed: {
    contact() {
      const contact = this.$store.getters.contactById(this.contactId);
      if (contact) return contact;
      // If the contact cannot be found (been deleted for example), we leave the page
      this.$f7router.back();
      return new Contact();
    },
    eventsOfContact() {
      return this.$store.getters.eventsOfContact(this.contactId);
    },
    phoneCleaned() {
      // For iPhone, we clean special characters off the phone
      if (!this.contact.phone) return null;
      return this.contact.phone.replace(/^\+/, '00').replace(/[^0-9]/g, '');
    },
    /*
    nextInteractionFromNow() {
      if (this.contact.nextInteraction && this.contact.nextInteraction.date) {
        return Moment(this.contact.nextInteraction.date).fromNow();
      }
      return null;
    },
    */
    nextReminderFromNow() {
      if (this.contact.futureInteractionDate) {
        if (!this.contact.lastInteraction) {
          return this.$t('nextReminder-neverContacted');
        }
        if (this.contact.isPostponed) {
          return this.$t('nextReminder-postponed-to-{date}', { date: Moment(this.contact.futureInteractionDate).format('DD MMM') });
        }
        const isToday = this.contact.futureInteractionDate.toDateString() === new Date().toDateString();
        return isToday ? this.$t('nextReminder-today') : Moment(this.contact.futureInteractionDate).fromNow();
      }
      return null;
    },
    lastInteractionFromNow() {
      if (this.contact.lastInteraction) {
        return Moment(this.contact.lastInteraction.date).fromNow();
      }
      return null;
    },
    /*
    linkNextInteraction() {
      if (this.contact.nextInteraction && this.contact.nextInteraction.id) {
        return `/interaction/${this.contact.nextInteraction.id}/`;
      }
      return null;
    },
    */
    linkLastInteraction() {
      if (this.contact.lastInteraction && this.contact.lastInteraction.id) {
        return `/event/${this.contact.lastInteraction.id}/`;
      }
      return null;
    },
  },
  mounted() {
    console.log('contact next interaction reminder', this.contact);
    Moment.locale(this.$i18n.locale);
    // Show Contact name in navbar on scroll
    const contactNameEl = this.$refs.contactName;
    this.$el.querySelector('.page-content').addEventListener('scroll', () => {
      const contactNamePos = contactNameEl.getBoundingClientRect().top;
      if (contactNamePos < 0) this.titleOpacity = '';
      else if (contactNamePos >= 45) this.titleOpacity = 0;
      else this.titleOpacity = 1 - (contactNamePos / 45);
    });
  },
  methods: {
    updateFrequency(value) {
      // The method is triggered when a contact is deleted, so we check the contact.id before
      if (this.contact.frequency !== value && this.contact.id) {
        this.$store.dispatch('updateContactFrequency', {
          contact: this.contact,
          frequency: value,
        });
      }
    },
    open(link, event, via) {
      const isCordova = this.$f7.device.cordova;
      const open = isCordova ? window.cordova.InAppBrowser.open : window.open;
      event.preventDefault();
      if (via) this.$f7router.navigate(`/p/event-new/?contact=${this.contactId}&type=${via}`);
      open(link, isCordova ? '_system' : '_self');
    },
  },
};
</script>

<style lang="less">
.navbar-previous .title.invisible {
  opacity: 0!important;
}

div[data-name="contact"] {

  .header {
    margin-top: 0px;
    padding-bottom: 0px;
  }
  h1 {
    font-size: 1.5em;
    text-align: center;
    margin: 0;
  }
  h2 {
    font-size: 1.2em;
    text-align: center;
    font-weight: 300;
    color: grey;
    margin-top: 0;
  }
  .info {
    margin: 0 -15px;
  }
  .info-shortcuts {
    text-align: center;
    align-items: center;
    padding-bottom: var(--f7-block-padding-vertical);
  }
  .info-toggle-col {
    width: 35px !important;
  }
  .info-shortcuts .accordion-item-toggle .item-inner::after {
    height: 0px;
  }
  .saving-loader {
    position: absolute;
    // top: 0px;
    width: 100%;
  }
  /*
  .saving-text {
    position: absolute;
    z-index: 10;
    top: 3px;
    right: 3px;
    font-size: 0.6em;
    color: var(--f7-preloader-color);
  }
  */
  .fab.fab-manual {
    margin-bottom: var(--f7-toolbar-height);
    bottom: calc(var(--f7-fab-margin) + var(--f7-safe-area-bottom));
    right: calc(var(--f7-fab-margin) + var(--f7-safe-area-right));

    .fab-label {
      right: 100%;
      margin-right: 8px;
    }
  }
  .timeline {
    .timeline-item-date {
      line-height: 15px;
      text-align: center;
    }
    .event-icon {
      font-size: var(--f7-list-font-size);
      color: var(--f7-timeline-item-time-text-color);
      vertical-align: baseline;
    }
    .memo ul {
      background-color: var(--event-bg-color-memo);
    }
    .mention ul {
      background-color: var(--event-bg-color-mention);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "createNewInteraction": "Create a new interaction",
    "interactionsHistory": "Interactions History",
    "lastInteraction": "Last Interaction",
    "nextInteraction": "Next Interaction",
    "noInteraction": "None",
    "noInteractionHistory": "You have not logged any interaction with this contact. Click on the + at the bottom right to log the first one!",
    "notes": "Notes",
    "nextReminder": "Next reminder",
    "nextReminder-today": "Today",
    "nextReminder-neverContacted": "Never contacted",
    "nextReminder-postponed-to-{date}": "Postponed to {date}",
    "frozen": "Frozen",
    "log-call": "Log Phone Call",
    "log-meeting": "Log face-to-face meeting",
    "log-email": "Log Email",
    "log-chat": "Log Text Chat",
    "log-memo": "Memo"
  },
  "fr": {
    "createNewInteraction": "Créer une nouvelle interaction",
    "interactionsHistory": "Historique des interactions",
    "lastInteraction": "Dernière Interaction",
    "nextInteraction": "Prochaine Interaction",
    "noInteraction": "Aucune",
    "noInteractionHistory": "Vous n'avez enregistré aucune interaction avec ce contact. Cliquez sur le + en bas à droite pour enregistrer la première !",
    "notes": "Notes",
    "nextReminder": "Prochain rappel",
    "nextReminder-today": "Aujourd'hui",
    "nextReminder-neverContacted": "Jamais contacté",
    "nextReminder-postponed-to-{date}": "Reporté au {date}",
    "frozen": "Gelé",
    "log-call": "Appel téléphonique",
    "log-meeting": "En face à face",
    "log-email": "Email envoyé",
    "log-chat": "Conversation écrite",
    "log-memo": "Mémo"
  }
}
</i18n>
