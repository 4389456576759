<template>
  <f7-page name="contacts-phone-list">
    <f7-navbar :back-link="$t('common.back')">
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link :class="{ disabled: !nbContactsChecked }" @click="next">
          {{ $t('common.next') }}
        </f7-link>
      </f7-nav-right>
      <f7-subnavbar :inner="false">
        <f7-searchbar ref="searchbar" :init="false" />
      </f7-subnavbar>
    </f7-navbar>
    <f7-block v-if="contactsLoading" strong>
      <p>Contacts Loading</p>
    </f7-block>
    <f7-block v-else-if="contactsError" strong>
      <p>{{ $t('error-access') }} {{ contactsError }}</p>
    </f7-block>
    <f7-list
      v-else
      ref="contacts"
      class="searchbar-found"
      virtual-list
      :virtual-list-params="{ items: contacts, searchAll, renderExternal }"
    >
      <ul>
        <f7-list-item
          v-for="(contact) in vlData.items"
          :key="contact.id"
          checkbox
          :checked="!!contact.checked"
          :title="contact.displayName"
          :style="`top: ${vlData.topPosition}px`"
          @change="checkContact($event, contact.id)"
        >
          <div slot="after">
            <f7-badge v-if="contact.emails">
              {{ contact.emails.length > 1 ? contact.emails.length : null }}@
            </f7-badge>
            <f7-badge v-if="contact.phones">
              {{ contact.phones.length > 1 ? contact.phones.length : null }} <f7-icon ios="f7:phone" aurora="f7:phone" md="material:phone" />
            </f7-badge>
          </div>
        </f7-list-item>
      </ul>
    </f7-list>
    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found" />
    </f7-list>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      contactsLoading: false,
      contactsError: null,
      contacts: [],
      nbContactsChecked: 0,
      vlData: {
        items: [],
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([]),
    selectedContacts() {
      return this.contacts.filter(contact => !!contact.checked);
    },
  },
  mounted() {
    const fields = navigator.contacts.fieldType;
    // eslint-disable-next-line no-undef
    const options = new ContactFindOptions();
    options.multiple = true;
    options.desiredFields = [fields.id, fields.name, fields.givenName, fields.familyName, fields.displayName, fields.emails, fields.phoneNumbers, fields.organization];
    options.filter = ''; // Search filter
    const searchFields = [fields.name, fields.displayName]; // Name
    navigator.contacts.find(searchFields, this.importSuccess, this.importError, options);
  },
  methods: {
    importSuccess(contactsPhone) {
      const contacts = [];
      contactsPhone.forEach((contact) => {
        const newContact = {
          id: contact.id,
          displayName: contact.displayName || (contact.name ? contact.name.formatted : null) || contact.organizations || null,
          firstname: (contact.name ? contact.name.givenName : null) || (contact.displayName ? contact.displayName.split(' ')[0] : null) || null,
          lastname: (contact.name ? contact.name.familyName : null) || (contact.displayName ? contact.displayName.split(' ').slice(1).join(' ') : null) || null,
          company: contact.organizations,
          phones: contact.phoneNumbers,
          emails: contact.emails,
        };
        if (newContact.displayName) {
          contacts.push(newContact);
        }
      });
      contacts.sort((a, b) => a.displayName.localeCompare(b.displayName));
      this.contacts = contacts;
      this.contactsLoading = false;
      // Reload virtualList and init Searchbar
      this.$refs.contacts.f7VirtualList.replaceAllItems(this.contacts);
      this.$f7.searchbar.create({
        el: this.$refs.searchbar.$el,
        searchContainer: this.$refs.contacts.$el,
      // searchIn: '.item-title',
      });
    },
    importError(contactsError) {
      this.contactsLoading = false;
      if (contactsError === 20 || contactsError.code === 20) { // android || ios
        this.contactsError = this.$t('error-access-contacts-unauthorized');
      } else {
        this.contactsError = contactsError;
      }
      console.error('onError!', contactsError);
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    checkContact(event, id) {
      const index = this.contacts.findIndex(contact => contact.id === id);
      this.nbContactsChecked += event.target.checked ? 1 : -1;
      this.contacts[index].checked = event.target.checked;
    },
    searchAll(query, contacts) {
      const found = [];
      for (let i = 0; i < contacts.length; i += 1) {
        if (query.trim() === '' || (contacts[i].displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0)) found.push(i);
      }
      return found; // return array with matched indexes
    },
    next() {
      if (this.nbContactsChecked) {
        this.$f7.views.current.router.navigate('/contacts-phone-selected/', { context: { selectedContacts: this.selectedContacts } });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  margin: 0;
}
</style>

<i18n>
{
  "en": {
    "title": "Import Phone Contacts",
    "error-access": "Error when loading contacts from phone:",
    "error-access-contacts-unauthorized": "you must go in your phones parameters to allow Kipinto to access your contacts."
  },
  "fr": {
    "title": "Importer des Contacts",
    "error-access": "Erreur lors du chargement des contacts du téléphone:",
    "error-access-contacts-unauthorized": "vous devez aller dans les paramètres de votre téléphone pour autoriser Kipinto à accéder à vos contacts."
  }
}
</i18n>
