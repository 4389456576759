import { makeDate } from './utils';

export default class Metadata {
  constructor(metadata = {}) {
    this.created = metadata.created;
    this.updated = metadata.updated;
  }

  exportToDB(noUpdate = false) {
    return {
      created: this.created,
      updated: noUpdate ? this.updated : new Date(),
    };
  }

  set created(v) {
    this._created = makeDate(v) || new Date();
  }

  get created() {
    return this._created;
  }

  set updated(v) {
    this._updated = makeDate(v) || new Date();
  }

  get updated() {
    return this._updated;
  }
}
