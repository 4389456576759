import Contact from './contact.class';
import Interaction from './interaction.class';
import Memo from './memo.class';
import UserDoc from './userDoc.class';

export const dbEventConverter = {
  toFirestore(dbEvent) {
    return dbEvent.exportToDB();
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    switch (data.type) {
      case 'interaction': return new Interaction(data, snapshot.id, snapshot.metadata.hasPendingWrites);
      case 'memo': return new Memo(data, snapshot.id, snapshot.metadata.hasPendingWrites);
      // default: throw new Error('unknown-dbevent-type');
      default: return new Interaction(data, snapshot.id, snapshot.metadata.hasPendingWrites); // TODO : removes this line and uncomment the line above
    }
  },
};

export const contactConverter = {
  toFirestore(contact) {
    return contact.exportToDB();
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return new Contact(data, snapshot.id, snapshot.metadata.hasPendingWrites);
  },
};

export const userDocConverter = {
  toFirestore(userDoc) {
    return userDoc.exportToDB();
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return new UserDoc(data, snapshot.id);
  },
};

export function dbEventFactory(eventFromDB, id) {
  switch (eventFromDB.type) {
    case 'interaction': return new Interaction(eventFromDB, id);
    case 'memo': return new Memo(eventFromDB, id);
    default: throw new Error('unknown-dbevent-type');
  }
}

export default {
  dbEventConverter,
  contactConverter,
  userDocConverter,
  dbEventFactory,
};
