import firebase from './firebase-config';

export default {
  state: {
    user: null,
    userMustVerifyEmail: false,
  },
  mutations: {
    setUser(state, user) {
      if (user) {
        state.user = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          isAnonymous: user.isAnonymous,
          providerData: user.providerData,
        };
        // We check if the user has verified its email (48h "grace" period)
        const creationDate = new Date(user.metadata.creationTime);
        const dayMinus48h = new Date(); dayMinus48h.setDate(dayMinus48h.getDate() - 2);
        state.userMustVerifyEmail = user && !user.isAnonymous && !user.emailVerified && creationDate < dayMinus48h;
      } else {
        state.user = null;
        state.userMustVerifyEmail = false;
      }
    },
  },
  actions: {
    signUserUp({ commit, dispatch }, { email, password }) {
      return new Promise((resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then((result) => {
            if (!result.user.emailVerified) {
              dispatch('sendEmailVerification');
            }
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    signUserIn({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(email, password)
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    signUserAnonymously({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInAnonymously()
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    signUserInGoogle({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())
          .then(() => firebase.auth().getRedirectResult()).then(
            (result) => {
              commit('setUser', result.user);
              resolve(result.user);
            },
          )
          .catch(error => reject(error));
      });
    },
    linkWithGoogle({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.linkWithRedirect(new firebase.auth.GoogleAuthProvider())
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    linkWithEmailPassword({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        firebase.auth().currentUser.linkWithCredential(credential)
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    reauthWithGoogle({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.reauthenticateWithRedirect(new firebase.auth.GoogleAuthProvider())
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    reauthWithEmailPassword({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        const credential = firebase.auth.EmailAuthProvider.credential(email, password);
        firebase.auth().currentUser.reauthenticateWithCredential(credential)
          .then((result) => {
            commit('setUser', result.user);
            resolve(result.user);
          })
          .catch(error => reject(error));
      });
    },
    unlinkProvider({ commit }, providerId) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.unlink(providerId)
          .then((user) => {
            commit('setUser', user);
            resolve(user);
          })
          .catch(error => reject(error));
      });
    },
    resetPassword(context, email) {
      return new Promise((resolve, reject) => {
        firebase.auth().sendPasswordResetEmail(email)
          .then(() => { resolve(); })
          .catch(error => reject(error));
      });
    },
    sendEmailVerification() {
      return new Promise((resolve, reject) => {
        if (firebase.auth().currentUser) {
          firebase.auth().currentUser.sendEmailVerification()
            .then(() => { resolve(); })
            .catch(error => reject(error));
        } else {
          reject(new Error('no-user'));
        }
      });
    },
    updateEmail({ getters, dispatch }, newEmail) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.updateEmail(newEmail)
          .then(() => {
            if (!getters.user.emailVerified) {
              dispatch('sendEmailVerification');
            }
            dispatch('reloadUser');
            resolve(getters.user);
          })
          .catch(error => reject(error));
      });
    },
    updatePassword(context, newPassword) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.updatePassword(newPassword)
          .then(() => resolve())
          .catch(error => reject(error));
      });
    },
    updateDisplayName({ dispatch }, newDisplayName) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.updateProfile({
          displayName: newDisplayName,
        })
          .then(() => {
            dispatch('reloadUser');
            resolve();
          })
          .catch(error => reject(error));
      });
    },
    reloadUser({ commit }) {
      return new Promise((resolve, reject) => {
        if (firebase.auth().currentUser) {
          firebase.auth().currentUser.reload()
            .then(() => {
              commit('setUser', firebase.auth().currentUser);
              resolve(firebase.auth().currentUser);
            })
            .catch(error => reject(error));
        } else {
          resolve(null);
        }
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        firebase.auth().signOut();
        commit('setUser', null);
        resolve();
      });
    },
    deleteUser() {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.delete()
          .then(() => resolve())
          .catch(error => reject(error));
      });
    },
  },
  getters: {
    user: state => state.user,
    userMustVerifyEmail: state => state.userMustVerifyEmail,
  },
};
