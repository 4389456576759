
<template>
  <f7-page name="event" class="page-event" :class="{ memo: event.isMemo }">
    <f7-navbar :back-link="backLink" :title="dateTitle">
      <f7-nav-right>
        <f7-link icon-ios="f7:square_pencil" icon-aurora="f7:square_pencil" icon-md="material:edit" :href="'/p/event-edit/'+$f7route.params.eventId+'/'" />
      </f7-nav-right>
    </f7-navbar>

    <f7-progressbar v-if="event.hasPendingWrites" infinite class="saving-loader" color="orange" />
    <!-- <span v-if="true" class="saving-text">saving</span> -->

    <event-header :event="event" show-via class="event-contacts" />

    <!-- <f7-block-title>{{ $t('interaction-notes') }}</f7-block-title> -->

    <f7-list no-hairlines-md class="event-notes-container">
      <!--
      <f7-progressbar v-if="interaction.hasPendingWrites" infinite class="saving-loader" />
      <span v-if="savingNotes" class="saving-text">saving</span>
      -->
      <notes-editor
        slot="list"
        class="event-notes"
        :object="event"
        auto-save
        :mentions-priority="contacts"
      />
    </f7-list>

    <f7-list form class="event-completed">
      <f7-list-item>
        <span>{{ event.isInteraction ? $t('form-completion-interaction') : $t('form-completion-memo') }}</span>
        <f7-toggle
          :checked="event.completed && event.date <= new Date()"
          :disabled="event.date > new Date()"
          @toggle:change="toggleCompleted"
        />
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import Moment from 'moment';
import Interaction from '../class/interaction.class';
import EventHeader from '../components/event-header.vue';
import NotesEditor from '../components/notes-editor.vue';

export default {
  components: { EventHeader, NotesEditor },
  data() {
    return {
      backLink: this.$f7route.query.back || this.$t('common.back'),
      eventId: this.$f7route.params.eventId,
      savingNotes: false,
    };
  },
  computed: {
    event() {
      const event = this.$store.getters.eventById(this.eventId);
      if (event) return event;
      // If the event cannot be found (been deleted for example), we leave the page
      this.$f7router.back();
      return new Interaction();
    },
    contacts() {
      const contacts = [];
      // We enrich the contacts list of the event with the contact name
      for (let c = 0; c < this.event.contacts.length; c++) {
        const contactId = this.event.contacts[c];
        const contact = this.$store.getters.contactById(contactId);
        if (contact) {
          contacts.push(contact);
        }
      }
      return contacts;
    },
    dateTitle() {
      return Moment(this.event.date).format('ll');
    },
  },
  /*
  mounted() {
    this.newNotes = this.interaction.notes; // Initialise the interaction notes
  },
  */
  methods: {
    /*
    saveNotes(event) {
      this.interaction.notes = event.target.value;
      console.log('Updating interaction notes');
      this.savingNotes = true;
      firebase
        .firestore()
        .doc(
          `users/${firebase.auth().currentUser.uid}/interactions/${
            this.$f7route.params.interactionId
          }`,
        )
        .update({ notes: this.interaction.notes })
        .then(() => {
          this.savingNotes = false;
          console.log('Interaction notes successfully updated!');
        });
    },
    */
    toggleCompleted(completed) {
      // The method is triggered when the interaction is deleted, so we check the interaction.id before
      if (this.event.id && this.event.completed !== completed) {
        this.$store.dispatch('updateEventCompletion', {
          event: this.event,
          completed,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-event.memo {
  --f7-list-bg-color: var(--event-bg-color-memo);
}
.page-event /deep/ .page-content {
  position: relative;
  display: flex;
  flex-direction: column;
}
.event-contacts,
.event-notes-container,
.event-completed {
  margin: 0;
}
.event-contacts /deep/ ul::before,
.event-notes-container /deep/ ul::before,
.event-notes-container /deep/ ul::after {
  content: none;
}
.event-notes-container {
  overflow: auto;
}
.event-notes-container,
.event-notes-container /deep/ ul,
.event-notes-container /deep/ .event-notes,
.event-notes-container /deep/ .item-content,
.event-notes-container /deep/ .item-inner,
.event-notes-container /deep/ .item-input-wrap,
.event-notes-container /deep/ .text-editor {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}
/*
.interaction-notes-container /deep/ .text-editor-content {
  height: auto;
}
*/
/*
.interaction-notes-container,
.interaction-notes-container /deep/ * {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}
.interaction-notes-container /deep/ .text-editor * {
  display: initial;
  flex-direction: initial;
  align-items: initial;
  flex-grow: initial;
}
*/
.saving-loader {
  position: absolute;
  //top: 0px;
  width: 100%;
}
/*
.saving-text {
  position: absolute;
  z-index: 10;
  top: 3px;
  right: 3px;
  font-size: 0.6em;
  color: var(--f7-preloader-color);
}
*/

/*
.page-interaction .interaction-type {
  position: absolute;
  top: var(--f7-navbar-height);
  color: #FFF;
  background-color: var(--f7-navbar-bg-color, var(--f7-bars-bg-color, var(--f7-theme-color)));
  height: var(--f7-navbar-height);
  width: var(--f7-navbar-height);
  line-height: var(--f7-navbar-height);
  border-radius: 50%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-align: center;
}
.page-interaction .interaction-header {
  margin-top: 0px;
}
@media screen and (min-height: 450px) {
  .interaction-header {
    position: -webkit-sticky;
    top: 0px;
    z-index: 30;
  }
}
.page-interaction h1 {
  font-size: 1.5em;
  text-align: center;
}
.page-interaction h2 {
  font-size: 1.2em;
  text-align: center;
  font-weight: lighter;
  color: grey;
}
.page-interaction .interaction-feedback textarea.resizable,
.page-interaction .interaction-description textarea.resizable {
  height: 100px;
}
.page-interaction .interaction-header .content-block-inner::before {
  height: 0px;
}*/
</style>

<i18n>
{
  "en": {
    "form-completion-interaction": "Interaction completed",
    "form-completion-memo": "Memo done",
    "interactions-title": "Interactions"
  },
  "fr": {
    "form-completion-interaction": "Interaction complétée",
    "form-completion-memo": "Mémo traité",
    "interactions-title": "Interactions"
  }
}
</i18n>
