<template>
  <f7-page name="contacts" ptr class="contacts-page" @ptr:refresh="onRefresh">
    <f7-navbar large>
      <f7-nav-left>
        <burger-button />
      </f7-nav-left>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-ios="f7:plus" icon-aurora="f7:plus" icon-md="material:add" href="/p/contact-new/" />
      </f7-nav-right>
      <f7-nav-title-large>{{ $t('title') }}</f7-nav-title-large>
      <f7-subnavbar :inner="false">
        <f7-searchbar
          :cancel-link="$t('common.cancel')"
          :placeholder="$t('searchInContacts')"
          :clear-button="true"
          :custom-search="true"
          @searchbar:search="search"
        />
      </f7-subnavbar>
    </f7-navbar>

    <empty-content
      v-if="Object.keys(contactsAlphaGrouped).length === 0 && !searchQuery"
      :title="$t('empty-title')"
      :text="$t('empty-text')"
    />

    <f7-list v-if="searchQuery && contactsFound.length" media-list class="no-margin">
      <contact-list-item v-for="contact in contactsFound" :key="contact.item.id" :contact="contact.item" :back-link="$t('title')" />
    </f7-list>
    <empty-content
      v-if="searchQuery && !contactsFound.length"
      :title="$t('searchNoResult-title')"
      :text="$t('searchNoResult-text')"
      search
    />

    <f7-list-index v-show="!searchQuery" ref="listIndex" :init="false" />
    <f7-list v-show="!searchQuery" ref="contactsList" contacts-list media-list>
      <f7-list-group v-for="(group, key) in contactsAlphaGrouped" :key="key">
        <f7-list-item :title="key" group-title />
        <contact-list-item v-for="contact in group" :key="contact.id" :contact="contact" :back-link="$t('title')" />
      </f7-list-group>
    </f7-list>
  </f7-page>
</template>

<script>
import Fuse from 'fuse.js';
import { mapGetters } from 'vuex';
import ContactListItem from '../components/contact-list-item.vue';
import BurgerButton from '../components/burger-button.vue';
import EmptyContent from '../components/empty-content.vue';

export default {
  components: { ContactListItem, BurgerButton, EmptyContent },
  data() {
    return {
      listIndex: null,
      searchQuery: null,
      mustUpdateIndex: false,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'contacts',
    ]),
    fuse() {
      const options = {
        includeScore: true,
        minMatchCharLength: 3,
        shouldSort: true,
        threshold: 0.4,
        useExtendedSearch: true,
        keys: [
          { name: 'name', weight: 0.7 },
          { name: 'company', weight: 0.2 },
          { name: 'notesRaw', weight: 0.1 },
        ],
        distance: 100,
      };
      return new Fuse(this.contacts, options);
    },
    contactsFound() {
      if (this.searchQuery && this.fuse) {
        return this.fuse.search(this.searchQuery);
      }
      return [];
    },
    contactsAlphaGrouped() {
      const groupedContacts = {};
      const contacts = this.contacts;
      contacts.forEach((contact) => {
        // We prepare to add the contact in the right "first-letter" group. Note: the "normalize/replace" removes the accents
        let firstLetter = contact.firstname[0].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
        // If not alphabetical, we had it to the # group
        if (!firstLetter.match(/[A-Z]/)) firstLetter = '#';
        // If the group does not already exists we initialize it
        if (!groupedContacts[firstLetter]) groupedContacts[firstLetter] = [];
        // We add the contact to the Array
        groupedContacts[firstLetter].push(contact);
      });
      // We sort the groups
      const sortedContacts = Object.keys(groupedContacts)
        .sort()
        .reduce((acc, key) => ({
          ...acc, [key]: groupedContacts[key],
        }), {});
      // We return the sorted contacts
      return sortedContacts;
    },
  },
  watch: {
    contactsAlphaGrouped() {
      this.$nextTick(() => {
        if (this.listIndex) {
          // If the list is hidden we simply flag it to be updated
          if (this.searchQuery) this.mustUpdateIndexAfterSearch = true;
          // Otherwise we update it
          else this.listIndex.update();
        }
      });
    },
    searchQuery(newVal, oldVal) {
      // If search is finished and the list index is waiting for an update
      if (oldVal && !newVal && this.mustUpdateIndexAfterSearch) {
        this.mustUpdateIndexAfterSearch = false;
        this.$nextTick(() => {
          if (this.listIndex) this.listIndex.update();
        });
      }
    },
  },
  mounted() {
    this.listIndex = this.$f7.listIndex.create({
      el: this.$refs.listIndex.$el,
      listEl: this.$refs.contactsList.$el,
      label: true,
    });
  },
  methods: {
    onRefresh(done) {
      this.$store.dispatch('testConnection')
        .then(() => {
          done();
        })
        .catch((error) => {
          this.$f7.dialog.alert(error);
          done();
        });
    },
    search(searchbar, query) {
      this.searchQuery = query;
    },
  },
};
</script>

<style lang="less" scoped>
// Make swipeouts start from "after" the letter on MD instead of from the left of the page
.md .contacts-list /deep/ li:not(.list-group-title) {
  padding-left: 0;
  margin-left: 56px;
}
</style>

<i18n>
{
  "en": {
    "empty-title": "No contact",
    "empty-text": "<p>You do not have any contact... yet.</p><p>Click the + button on the top-right<br />to create your first contact!</p>",
    "searchInContacts": "Search in contacts",
    "searchNoResult-title": "No result",
    "searchNoResult-text": "<p>We did not find any contacts<br>matching your search.</p><p><em>And yet we searched everywhere:<br>First name, Last name, Company & Notes!</em></p>",
    "title": "Contacts"
  },
  "fr": {
    "empty-title": "Aucun contact",
    "empty-text": "<p>Vous n'avez aucun contact... pour le moment.</p><p>Cliquez sur le bouton + en haut à droite<br />pour créer votre premier contact !</p>",
    "searchInContacts": "Chercher parmi les contacts",
    "searchNoResult-title": "Aucun résultat",
    "searchNoResult-text": "<p>Nous n'avons trouvé aucun contact<br>correspondant à votre recherche.</p><p><em>Et pourtant nous avons cherché partout :<br>prénom, nom, entreprise & notes !</em></p>",
    "title": "Contacts"
  }
}
</i18n>
