<template>
  <f7-page name="backup-restore">
    <f7-navbar>
      <f7-nav-title>{{ $t('title') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popup-close>
          {{ $t('common.close') }}
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-block class="text-align-center">
      <h3>Vos données vous appartiennent !</h3>
    </f7-block>
    <f7-block-title>Backup</f7-block-title>
    <f7-block strong class="text-align-center">
      <p>Téléchargez un fichier prêt à être réimporté dans Kipinto dans l'éventualité d'une erreur de manipulation.</p>
      <p>
        <f7-button v-if="backupLoading" fill large round disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button v-else fill large round @click="dlBackup">
          {{ $t('backup-confirmation-title') }}
        </f7-button>
      </p>
    </f7-block>
    <f7-block-title>Restore</f7-block-title>
    <f7-block strong class="text-align-center">
      <p>Un nouveau compte ? Un accident ? Restaurez vos données issues d'un backup précédent.</p>
      <p>
        <f7-button v-if="restoreLoading" fill large round disabled>
          <f7-preloader color="white" />
        </f7-button>
        <f7-button v-else fill large round @click="$refs.restoreFileInput.click()">
          {{ $t('restore-button') }}
        </f7-button>
        <input ref="restoreFileInput" type="file" style="display: none" @change="getFile">
      </p>
      <p><strong>{{ $t("restore-warning") }}</strong></p>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      backupLoading: false,
      restoreLoading: false,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(['user', 'userDoc']),
  },
  mounted() {},
  methods: {
    dlBackup() {
      const lastBackup = this.userDoc.lastBackup;
      const debounceDate = new Date(Date.now() - (24 * 60 * 60 * 1000));
      this.$f7.dialog.confirm(
        lastBackup > debounceDate ? this.$t('backup-confirmation-text-debounce') : this.$t('backup-confirmation-text'),
        this.$t('backup-confirmation-title'),
        async () => {
          this.backupLoading = true;
          const backup = await this.$store.dispatch('downloadBackup');
          const linkSource = `data:application/gzip;base64,${backup}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = `backup-${this.user.uid}-${(new Date()).toISOString().slice(0, 10)}.kipinto`;
          downloadLink.click();
          this.backupLoading = false;
        },
      );
    },
    getFile(evt) {
      const file = evt.target.files[0]; // FileList object

      if (!file) return;
      if (file.size > 1000000) throw new Error('File too big');
      if (file.name.slice(-8) !== '.kipinto') throw new Error('Not a Kipinto backup');

      this.restoreLoading = true;

      const reader = new FileReader();
      reader.onload = () => {
        this.uploadFile(btoa(reader.result));
      };
      reader.readAsBinaryString(file);
      this.$refs.restoreFileInput.value = null;
    },
    uploadFile(backupJsonGzipBase64) {
      this.$f7.dialog.prompt(
        this.$t('restore-confirmation-text'),
        this.$t('restore-confirmation-title'),
        async (confirmationWord) => {
          if (confirmationWord === this.$t('restore-confirmation-word')) {
            const result = await this.$store.dispatch('uploadRestore', backupJsonGzipBase64);
            console.log('result restore', result);
          }
          this.restoreLoading = false;
        },
        () => {
          this.restoreLoading = false;
        },
      );
    },
  },
};
</script>

<style lang="less" scoped>

</style>

<i18n>
{
  "en": {
    "title": "Backup & Restore",
    "backup-confirmation-title": "Download Backup",
    "backup-confirmation-text": "Please note: only 1 backup will be generated per 24h.",
    "backup-confirmation-text-debounce": "Please note: you already made a backup in the last 24h. The file you will download will not take into account any changes made since then.",
    "restore-button": "Select a backup to restore",
    "restore-warning": "Your current data will be deleted.",
    "restore-confirmation-title": "Ready to Restore",
    "restore-confirmation-text": "<p>All your account data will be wiped out and replaced by those from the backup you provided. <strong>We strongly recommend that you make a backup beforehand.</strong></p><p>Write the word <em>restore</em> to confirm.</p>",
    "restore-confirmation-word": "restore"
  },
  "fr": {
    "title": "Sauvegarde & Restauration",
    "backup-confirmation-title": "Télécharger un backup",
    "backup-confirmation-text": "A noter : un seul backup sera généré par 24h.",
    "backup-confirmation-text-debounce": "Attention : vous avez déjà effectué un backup ces dernières 24h. Le fichier que vous allez télécharger ne prendra pas en compte les éventuelles modifications effectuées depuis.",
    "restore-button": "Choisir un backup à restaurer",
    "restore-warning": "Vos données actuelles seront supprimées.",
    "restore-confirmation-title": "Prêt pour la Restauration",
    "restore-confirmation-text": "<p>Toutes les données de votre compte vont être supprimées et remplacées par celles du backup fourni. <strong>Nous vous recommandons fortement de faire un backup des données actuelles avant.</strong></p><p>Ecrivez le mot <em>restauration</em> pour confirmer.</p>",
    "restore-confirmation-word": "restauration"
  }
}
</i18n>
