import { makeDate } from './utils';

export default class UserDoc {
  constructor(fromDB = {}, userId) {
    this.id = userId || null;
    this.stats = {
      contact: fromDB.stats && fromDB.stats.contact && Number.isInteger(fromDB.stats.contact) ? fromDB.stats.contact : 0,
      interaction: fromDB.stats && fromDB.stats.interaction && Number.isInteger(fromDB.stats.interaction) ? fromDB.stats.interaction : 0,
      memo: fromDB.stats && fromDB.stats.memo && Number.isInteger(fromDB.stats.memo) ? fromDB.stats.memo : 0,
    };
    this.hashtags = {};
    if (fromDB.hashtags) {
      Object.entries(fromDB.hashtags).forEach(([hashtag, count]) => {
        if (Number.isInteger(count) && count > 0) {
          this.hashtags[hashtag] = count;
        }
      });
    }
    this.lastBackup = makeDate(fromDB.lastBackup);
    this.lastRestore = makeDate(fromDB.lastRestore);
    this.lockedUntil = makeDate(fromDB.lockedUntil);
    this.dbUpdated = makeDate(fromDB.dbUpdated);
    this.ical = {
      token: fromDB.ical && fromDB.ical.token ? fromDB.ical.token : null,
      updated: fromDB.ical && fromDB.ical.updated ? makeDate(fromDB.ical.updated) : null,
    };
    this.version = fromDB.version || null;
  }

  exportToDB() {
    return {
      stats: this.stats,
      hashtags: this.hashtags,
      lastBackup: this.lastBackup,
      lastRestore: this.lastRestore,
      lockedUntil: this.lockedUntil,
      dbUpdated: this.dbUpdated,
      ical: this.ical,
      version: this.version,
    };
  }
}
